import React from 'react';
import { Form, Input, InputPicker, Schema } from 'rsuite';
import Modal from './Modal';
import { GridButtons, GridFormInputs, RequiredIndicator, TextareaWrapper } from '../styles/ModalStyles';
import { addNewContact } from '../services/contactService';
import Button from './Button';
import { showErrorNotification } from '../services/notificationService';
import { isValidPhoneNumber } from 'libphonenumber-js';
import AreaCodes from '../model/AreaCodes';
import { sendPassword } from '../services/passwordService';
import { IOpenModal, ISmsMessageModal } from '../pages/dashboard/Dashboard';
import { TypeAttributes } from 'rsuite/esm/@types/common';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

interface ModalNewContactProps {
    openModal: IOpenModal;
    setOpenModal: (openModal: any) => void;
    addedNewContactSuccessfully: () => void;
    smsMessageModal: ISmsMessageModal;
    setSmsMessageModal: (smsMessageModal: any) => void;
}

const Textarea = React.forwardRef<any>((props, ref) => <Input {...props} as="textarea" rows={4} ref={ref} />);

interface ContactForm {
    email: string;
    areaCode: string;
    phoneNumber: string;
    description: string;
}

const ModalNewContact: React.FC<ModalNewContactProps> = (props) => {
    const { t } = useTranslation();
    const { StringType } = Schema.Types;
    const isSmsMessages = useSelector((state: RootState) => state.featureToggle.smsMessages);
    const model = Schema.Model({
        email: StringType()
            .isEmail(t('COMMON.IS_EMAIL') as string)
            .isRequired(t('COMMON.IS_REQUIRED') as string),
        phoneNumber: StringType()
            .isRequired(t('COMMON.IS_REQUIRED') as string)
            .addRule(
                (value) => isValidPhoneNumber(formValue.areaCode + value),
                t('MODAL_NEW_CONTACT.IS_VALID_AREA_CODE') as string
            )
    });

    const formRef = React.useRef<any>();

    const initialState: ContactForm = { email: '', areaCode: '+48', phoneNumber: '', description: '' };
    const [formValue, setFormValue] = React.useState<any>(initialState);
    const [formError, setFormError] = React.useState<any>({});
    const [checkTrigger, setCheckTrigger] = React.useState<TypeAttributes.CheckTrigger>('change');

    const handleCloseModal = () => {
        setFormValue(initialState);
        props.setOpenModal({ ...props.openModal, openNewContactModal: false });
        setFormError({});
    };

    const isFormValid = () => {
        return formRef.current.check();
    };

    const sendPasswordForNewContact = async (contactId: string) => {
        try {
            const response = await sendPassword(contactId, true);
            if (!isSmsMessages) {
                props.setSmsMessageModal({
                    openModal: true,
                    smsMessage: t('MODAL_SMS_MESSAGE.NEW_CONTACT_MESSAGE'),
                    password: response.data.password
                });
            }
            handleCloseModal();
            props.addedNewContactSuccessfully();
        } catch (e) {
            showErrorNotification(t('NOTIFICATION.FAILED_SEND_PASSWORD') as string);
        }
    };

    const handleSubmit = (passSend: boolean) => {
        return addNewContact(formValue.email, formValue.areaCode + formValue.phoneNumber, formValue.description)
            .then(async (response) => {
                if (passSend) {
                    await sendPasswordForNewContact(response.clientId);
                } else {
                    handleCloseModal();
                    props.addedNewContactSuccessfully();
                }
            })
            .catch((err) => {
                let isErrorShown = false;
                if (err.response && err.response.status === 400) {
                    err.response.data.errors.forEach((error: any) => {
                        if (error.field === 'clientEmail' && error.error === 'invalidFieldValue') {
                            showErrorNotification(t('MODAL_NEW_CONTACT.INCORRECT_EMAIL_ADDRESS') as string);
                            isErrorShown = true;
                        }
                        if (error.field === 'clientPhoneNumber' && error.error === 'validationFieldValueExists') {
                            showErrorNotification(t('MODAL_NEW_CONTACT.PHONE_NUMBER_ALREADY_EXISTS') as string);
                            isErrorShown = true;
                        }
                        if (error.field === 'clientPhoneNumber' && error.error === 'invalidFieldValue') {
                            showErrorNotification(t('MODAL_NEW_CONTACT.INVALID_PHONE_NUMBER') as string);
                            isErrorShown = true;
                        }
                    });
                }

                if (!isErrorShown) {
                    showErrorNotification(t('NOTIFICATION.FAILED_CREATE_CLIENT') as string);
                }
            });
    };

    return (
        <Modal
            open={props.openModal.openNewContactModal}
            onClose={handleCloseModal}
            title={t('MODAL_NEW_CONTACT.HEADER')}
            body={
                <>
                    <h5>{t('CONTACT.CONTACT_DETAILS')}</h5>
                    <Form
                        ref={formRef}
                        onChange={setFormValue}
                        onCheck={setFormError}
                        formError={formError}
                        formDefaultValue={formValue}
                        model={model}
                        checkTrigger={checkTrigger}
                    >
                        <GridFormInputs>
                            <Form.Group>
                                <Form.ControlLabel className="modalNewContactLabel">
                                    <RequiredIndicator>*</RequiredIndicator>
                                    {t('CONTACT.EMAIL')}
                                </Form.ControlLabel>
                                <Form.Control name="email" errorMessage={formError.email} />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel className="modalNewContactLabel">
                                    <RequiredIndicator>*</RequiredIndicator>
                                    {t('CONTACT.PHONE')}
                                </Form.ControlLabel>
                                <Form.Control
                                    name="areaCode"
                                    accepter={InputPicker}
                                    data={AreaCodes}
                                    defaultValue={'+48'}
                                    cleanable={false}
                                    style={{ width: 80, marginRight: 5 }}
                                    renderValue={() => formValue.areaCode}
                                />
                                <Form.Control
                                    name="phoneNumber"
                                    errorMessage={formError.phoneNumber}
                                    style={{ width: 215 }}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel className="modalNewContactLabel">
                                    {t('CONTACT.DESCRIPTION')}
                                </Form.ControlLabel>
                                <TextareaWrapper>
                                    <Form.Control name="description" accepter={Textarea} style={{ width: '100%' }} />
                                </TextareaWrapper>
                            </Form.Group>
                        </GridFormInputs>
                        <Form.Group>
                            <GridButtons>
                                <Button
                                    appearance={'primary'}
                                    canClick={isFormValid}
                                    onClick={() => handleSubmit(true)}
                                    text={t('MODAL_NEW_CONTACT.ADD_CONTACT_AND_SEND_PASSWORD')}
                                />
                                <Button
                                    appearance={'ghost'}
                                    canClick={isFormValid}
                                    onClick={() => handleSubmit(false)}
                                    text={t('MODAL_NEW_CONTACT.ADD_CONTACT')}
                                />
                                <Button appearance={'link'} onClick={handleCloseModal} text={t('COMMON.CANCEL')} />
                            </GridButtons>
                        </Form.Group>
                    </Form>
                </>
            }
        />
    );
};

export default ModalNewContact;
