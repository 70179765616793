import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import React, { useContext } from 'react';
import './App.css';
import 'rsuite/dist/rsuite.min.css';
import Navbar from './components/Navbar';
import Dashboard from './pages/dashboard/Dashboard';
import LoginPage from './pages/login/LoginPage';
import HistoryPage from './pages/history/HistoryPage';
import { UserContext } from './context/UserContext';
import { Container, Footer } from 'rsuite';
import NotFoundPage from './pages/notFound/NotFoundPage';
import FileDecryptionPage from './pages/clientFileDecryption/FileDecryptionPage';
import LoaderWrapper from './components/LoaderWrapper';
import UiBlock from './components/UiBlock';
import { CognitoUser } from 'amazon-cognito-identity-js';
import RegisterPage from './pages/register/RegisterPage';
import ForgotPasswordPage from './pages/forgotPassword/ForgotPasswordPage';
import ConfirmUserPage from './pages/register/ConfirmUserPage';
import ResetPasswordPage from './pages/forgotPassword/ResetPasswordPage';

function App() {
    const { user } = useContext(UserContext);

    return (
        <>
            <BrowserRouter>
                <Container>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <ProtectedRoute user={user}>
                                    <Dashboard />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="history/:userId"
                            element={
                                <ProtectedRoute user={user}>
                                    <HistoryPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="login"
                            element={
                                <LoginPageRoute user={user}>
                                    <LoginPage />
                                </LoginPageRoute>
                            }
                        />
                        <Route path="register" element={<RegisterPage />} />
                        <Route path="confirm-user" element={<ConfirmUserPage />} />
                        <Route path="forgot-password" element={<ForgotPasswordPage />} />
                        <Route path="reset-password" element={<ResetPasswordPage />} />
                        <Route path="*" element={<NotFoundPage />} />

                        {/*FIXME testing*/}
                        <Route
                            path="file-decryption"
                            element={
                                <LoginPageRoute>
                                    <FileDecryptionPage />
                                </LoginPageRoute>
                            }
                        />
                    </Routes>
                </Container>
            </BrowserRouter>
            <LoaderWrapper />
            <UiBlock />
        </>
    );
}

interface ProtectedRouteProps {
    user?: CognitoUser | any;
    children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
    if (!props.user) {
        return <Navigate to="/login" replace />;
    }

    return (
        <>
            <Navbar />
            {props.children}
            <Footer>
                <a href="https://vilisoft.com/" target="_blank" rel="noreferrer">
                    <img src="/assets/img/logoVilisoft.png" alt="Vilisoft logo" />
                </a>
            </Footer>
        </>
    );
};

const LoginPageRoute: React.FC<ProtectedRouteProps> = (props) => {
    if (props.user) {
        return <Navigate to="/" replace />;
    }

    return <>{props.children}</>;
};

export default App;
