import styled from 'styled-components';

export const Header = styled.header`
    & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 60px;

        @media (max-width: 767px) {
            margin-top: 10px;
            margin-bottom: 30px;
        }
    }

    & > div::before,
    & > div::after {
        display: none !important;
    }

    & > div > button > svg {
        width: 25px;
        height: 25px;
        z-index: 1000;
    }
`;
