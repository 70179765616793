import styled from 'styled-components';

export const PopoverContent = styled.div`
    width: 300px;
`;

export const CopyInfoWrapper = styled.div`
    display: flex;
    align-items: center;
`;
