import React from 'react';
import { ContactTitleDetails, GridContactDetails } from '../styles/ContactDetailsStyles';

interface ContactDetailsProps {
    header: string;
    details?: string;
}

const ContactDetails: React.FC<ContactDetailsProps> = (props) => {
    return (
        <GridContactDetails>
            <ContactTitleDetails>{props.header}</ContactTitleDetails>
            <p>{props.details}</p>
        </GridContactDetails>
    );
};

export default ContactDetails;
