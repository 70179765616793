import { configureStore } from '@reduxjs/toolkit';
import loaderReducer from './loader';
import uiBlockReducer from './uiBlock';
import featureToggleReducer from './featureToggle';

const store = configureStore({
    reducer: {
        loader: loaderReducer,
        uiBlock: uiBlockReducer,
        featureToggle: featureToggleReducer
    }
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
