import React from 'react';
import { Modal as ModalRsuite } from 'rsuite';

interface ModalProps {
    open: boolean;
    onClose: () => void;
    onExit?: () => void;
    onExited?: () => void;
    title: string;
    body?: React.ReactNode;
    footer?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = (props) => {
    return (
        <ModalRsuite
            open={props.open}
            onClose={props.onClose}
            onExit={props.onExit}
            onExited={props.onExited}
            backdrop={'static'}
            keyboard={false}
            className="modal"
            backdropClassName="modalBackdrop"
        >
            <ModalRsuite.Header>
                <ModalRsuite.Title classPrefix="modalTitle">{props.title}</ModalRsuite.Title>
            </ModalRsuite.Header>
            <ModalRsuite.Body>{props.body}</ModalRsuite.Body>
            <ModalRsuite.Footer>{props.footer}</ModalRsuite.Footer>
        </ModalRsuite>
    );
};

export default Modal;
