import styled from 'styled-components';

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > img {
        margin: 40px 20px;
    }

    & > p {
        margin: 20px;
    }
`;

export const Grid = styled.div`
    background: #f5f9ff;
    border-radius: 32px;
    max-width: 795px;
    padding: 60px 100px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > h3,
    & > form {
        margin-bottom: 20px;
    }

    & > p {
        text-align: center;
        margin-bottom: 20px;
    }
`;

export const FirstStepWrapper = styled.div`
    max-width: 385px;

    & > p {
        color: #637292;
        margin-bottom: 5px;
    }

    & > form {
        margin-bottom: 30px;
    }
`;

export const SecondStepWrapper = styled.div`
    max-width: 385px;
`;

export const InputWrapper = styled.div`
    margin: 30px 0;

    & > input {
        letter-spacing: 4px;
    }

    & > p {
        color: #637292;
        margin-bottom: 5px;
    }
`;
