import { API } from 'aws-amplify';
import { getAuthorizationBearer } from './authService';
import { IQueryStringParameters, TableSearchParameters } from '../model/TableHelpers';

const prepareContactsGetInit = async (limit: number | null, filter?: string, lastKey?: string) => {
    const getInit = {
        headers: {
            Authorization: await getAuthorizationBearer()
        },
        queryStringParameters: {} as IQueryStringParameters,
        response: true
    };
    if (filter != null) {
        getInit.queryStringParameters.filter = filter;
        return getInit;
    }

    limit != null && (getInit.queryStringParameters.limit = limit);
    lastKey != null && (getInit.queryStringParameters['last-key'] = lastKey);
    return getInit;
};

export interface ContactTableSearchParameters extends TableSearchParameters {
    showDisabled: boolean;
}

export const getContacts = async (tableSearchParameters: ContactTableSearchParameters) => {
    const getInit = await prepareContactsGetInit(
        tableSearchParameters.limit,
        tableSearchParameters.filter,
        tableSearchParameters.lastKey
    );
    tableSearchParameters.showDisabled != null &&
        tableSearchParameters.showDisabled &&
        (getInit.queryStringParameters.disabled = tableSearchParameters.showDisabled);
    return API.get('DocumentSafeAPI', '/clients', getInit).then((response) => response.data);
};

export const getContactsSelect = async (): Promise<{ id: string; email: string }[]> => {
    const getInit = {
        headers: {
            Authorization: await getAuthorizationBearer()
        },
        response: true
    };
    return API.get('DocumentSafeAPI', '/clients/select', getInit).then((response) => response.data.clients);
};

export const getContact = async (id: string) => {
    const getInit = {
        headers: {
            Authorization: await getAuthorizationBearer()
        },
        response: true
    };
    return API.get('DocumentSafeAPI', `/clients/${id}`, getInit).then((response) => response.data);
};

export const disableContact = async (id: string) => {
    const putInit = {
        headers: {
            Authorization: await getAuthorizationBearer()
        }
    };
    return API.put('DocumentSafeAPI', `/clients/${id}/actions/disable`, putInit);
};

export const editContact = async (id: string, email: string, description: string) => {
    const putInit = {
        headers: {
            Authorization: await getAuthorizationBearer()
        },
        body: {
            email: email,
            description: description
        }
    };
    return API.put('DocumentSafeAPI', `/clients/${id}`, putInit);
};

export const getContactAudits = async (id: string, limit: number | null, filter?: string, lastKey?: string) => {
    const getInit = await prepareContactsGetInit(limit, filter, lastKey);
    return API.get('DocumentSafeAPI', `/clients/${id}/audits`, getInit).then((response) => response.data);
};

export const addNewContact = async (email: string, phone: string, description: string) => {
    const postInit = {
        headers: {
            Authorization: await getAuthorizationBearer()
        },
        body: {
            email: email,
            phoneNumber: phone,
            description: description
        }
    };

    return API.post('DocumentSafeAPI', '/clients', postInit);
};
