import React from 'react';
import { BoxShadowWrapper, Container, FormWrapper } from '../../styles/ForgotPasswordStyles';
import { useTranslation } from 'react-i18next';
import { Form, Schema } from 'rsuite';
import { TypeAttributes } from 'rsuite/esm/@types/common';
import Button from '../../components/Button';
import { Auth } from 'aws-amplify';
import { TextWrapper } from '../../styles/RegisterStyles';
import { showErrorNotification, showSuccessNotification } from '../../services/notificationService';
import { Link } from '../../styles/UtilsStyles';

export interface IUserState {
    userMail: string;
}

const ForgotPasswordPage = () => {
    const formRef = React.useRef<any>();
    const [formValue, setFormValue] = React.useState<any>(null);
    const [formError, setFormError] = React.useState<any>({});
    const [checkTrigger, setCheckTrigger] = React.useState<TypeAttributes.CheckTrigger>('change');
    const [showSuccessMessage, setShowSuccessMessage] = React.useState<boolean>(false);
    const [userState, setUserState] = React.useState<IUserState>({
        userMail: ''
    });

    const { t } = useTranslation();

    const { StringType } = Schema.Types;
    const model = Schema.Model({
        email: StringType()
            .isEmail(t('COMMON.IS_EMAIL') as string)
            .isRequired(t('COMMON.IS_REQUIRED') as string)
    });

    const handleSendCode = async () => {
        try {
            await Auth.forgotPassword(formValue.email);
            setUserState({ ...userState, userMail: formValue.email });
            showSuccessNotification(t('FORGOT_PASSWORD_PAGE.EMAIL_SENT') as string);
            setShowSuccessMessage(!showSuccessMessage);
        } catch (err) {
            errorNotification(err);
        }
    };

    const handleResendCode = async () => {
        try {
            await Auth.forgotPassword(userState.userMail);
            showSuccessNotification(t('FORGOT_PASSWORD_PAGE.EMAIL_SENT') as string);
        } catch (err) {
            errorNotification(err);
        }
    };

    const errorNotification = (err: any) => {
        if (err) {
            if (err.toString().includes('LimitExceededException')) {
                showErrorNotification(t('NOTIFICATION.ATTEMPT_LIMIT_EXCEEDED') as string);
            } else if (err.toString().includes('UserNotConfirmedException')) {
                showErrorNotification(t('NOTIFICATION.USER_NOT_CONFIRMED') as string);
            } else if (err.toString().includes('InvalidParameterException')) {
                showErrorNotification(t('NOTIFICATION.USER_NOT_CONFIRMED') as string);
            } else if (err.toString().includes('UserNotFoundException')) {
                showErrorNotification(t('NOTIFICATION.USER_NOT_FOUND') as string);
            }
        } else {
            showErrorNotification(t('FORGOT_PASSWORD_PAGE.ERROR_SEND_CODE_MESSAGE') as string);
        }
    };

    const isFormValid = () => {
        return formValue && formValue.email && !formError.email;
    };

    return (
        <Container>
            <img src="/assets/img/logo.svg" alt={t('ALT.KRYPTLI_APP_LOGO') as string} />
            <BoxShadowWrapper>
                {!showSuccessMessage && (
                    <FormWrapper>
                        <h3>{t('FORGOT_PASSWORD_PAGE.FORGOT_PASSWORD')}</h3>
                        <p>{t('FORGOT_PASSWORD_PAGE.TO_RESET_PASSWORD')}</p>
                        <Form
                            ref={formRef}
                            onChange={setFormValue}
                            onCheck={setFormError}
                            formError={formError}
                            model={model}
                            checkTrigger={checkTrigger}
                        >
                            <Form.Group controlId="email">
                                <Form.ControlLabel>{t('FORGOT_PASSWORD_PAGE.EMAIL')}</Form.ControlLabel>
                                <Form.Control name="email" />
                            </Form.Group>
                            <Form.Group>
                                <Button
                                    appearance="primary"
                                    onClick={handleSendCode}
                                    text={t('FORGOT_PASSWORD_PAGE.RESET_PASSWORD')}
                                    type="submit"
                                    disabled={!isFormValid()}
                                />
                            </Form.Group>
                        </Form>
                    </FormWrapper>
                )}

                {showSuccessMessage && (
                    <>
                        <TextWrapper>
                            <h3>{t('FORGOT_PASSWORD_PAGE.HEADER_MESSAGE')}</h3>
                            <p>
                                {t('FORGOT_PASSWORD_PAGE.MESSAGE1')}
                                <strong>{userState.userMail}</strong>
                                {t('FORGOT_PASSWORD_PAGE.MESSAGE2')}
                            </p>
                            <Button appearance={'primary'} onClick={handleResendCode} text={t('REGISTER.SEND_AGAIN')} />
                            <Link href="/login">{t('COMMON.GO_BACK_LOGIN_PAGE')}</Link>
                        </TextWrapper>
                    </>
                )}
            </BoxShadowWrapper>
        </Container>
    );
};

export default ForgotPasswordPage;
