import styled from 'styled-components';

export const Text = styled.div`
    width: 300px;
    margin-bottom: 20px;
`;

export const TextWrapper = styled.div`
    max-width: 415px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;

    & > h3,
    & > p {
        margin-bottom: 20px;
    }

    & > button {
        width: 300px;
    }

    & > a {
        margin: 20px 0;
    }
`;

export const MarginBottom = styled.div`
    margin-bottom: 40px;
`;
