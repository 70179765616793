import React from 'react';
import { List } from 'rsuite';
import { ContactTitleDetails, GridContactDetails } from '../styles/ContactDetailsStyles';

interface ContactDetailsListProps {
    header: string;
    details: string[];
}

const ContactDetailsList: React.FC<ContactDetailsListProps> = (props) => {
    return (
        <GridContactDetails>
            <ContactTitleDetails>{props.header}</ContactTitleDetails>
            <List autoScroll={true} hover={true} size="sm" style={{ maxHeight: 150, boxShadow: 'none' }}>
                {props.details.map((item, index) => (
                    <List.Item size="sm" key={index} index={index}>
                        {item}
                    </List.Item>
                ))}
            </List>
        </GridContactDetails>
    );
};

export default ContactDetailsList;
