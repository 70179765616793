class AuditType {
    static from(audit: any): AuditType {
        //FIXME nowy kod decryptPublicFile - odszyfrowanie pliku przez klienta
        switch (audit.operationType) {
            case 'sendPassword':
                return new AuditType('Wysłanie hasła');
            case 'generatePassword':
                return new AuditType('Generowanie hasła');
            case 'encryptFile':
                return new AuditType('Szyfrowanie pliku', `Nazwa pliku: ${audit.fileName}`);
            case 'decryptFile':
                return new AuditType('Odszyfrowanie pliku', `Nazwa pliku: ${audit.fileName}`);
            case 'editClient':
                return new AuditType(
                    'Edycja kontaktu',
                    `
                    ${
                        audit.hasOwnProperty('oldClientEmail')
                            ? `Zmiana email z "${audit.oldClientEmail}" na "${audit.newClientEmail}"`
                            : ''
                    }
                    ${
                        audit.hasOwnProperty('oldClientDescription')
                            ? `Zmiana opisu z "${audit.oldClientDescription}" na "${audit.newClientDescription}"`
                            : ''
                    }
        `
                );
            case 'disableClient':
                return new AuditType('Dezaktywacja kontaktu');
            default:
                return new AuditType('Błąd - skontaktuj się z administratorem');
        }
    }

    constructor(public name: string, public message?: string) {
        this.name = name;
        this.message = message;
    }
}

export default AuditType;
