import React, { useState } from 'react';
import { Checkbox, Uploader } from 'rsuite';
import { DecryptionUploaderWrapper, GridButtons, SuccessMessage, UploaderWrapper } from '../styles/ModalStyles';
import { showErrorNotification } from '../services/notificationService';
import Modal from './Modal';
import Button from './Button';
import ContactDetails from './ContactDetails';
import ContactDetailsList from './ContactDetailsList';
import { decryptFile, getDecryptUrl } from '../services/decryptServices';
import { formatDate } from '../utils/dateUtils';
import { FileType } from 'rsuite/Uploader';
import { useTranslation } from 'react-i18next';
import { downloadFile } from '../services/downloadFile';

interface ModalFileDecryptionProps {
    openModalFileDecryption: boolean;
    setOpenModalFileDecryption: (openModalFileDecryption: boolean) => void;
}

interface IContact {
    id: string;
    email: string;
}

interface IUploadFileState {
    url: string;
    fileName: string;
    contacts: IContact[];
    createDate: string;
    creator: string;
    encryptionMethod: string;
    showMessage: boolean;
}

const ModalFileDecryption: React.FC<ModalFileDecryptionProps> = (props) => {
    const [value, setValue] = useState<any>([]);
    const [uploadFileState, setUploadFileState] = useState<IUploadFileState>({
        url: '',
        fileName: '',
        contacts: [],
        createDate: '',
        creator: '',
        encryptionMethod: '',
        showMessage: false
    });
    const [restoreFileName, setRestoreFileName] = useState(false);
    const maxFileSize = 1024 * 1024 * 25;

    const { t } = useTranslation();

    const handleCloseModalFileDecryption = () => {
        props.setOpenModalFileDecryption(false);
    };

    const handleExitModalDecryption = () => {
        props.setOpenModalFileDecryption(false);
        setUploadFileState({
            url: '',
            fileName: '',
            contacts: [],
            createDate: '',
            creator: '',
            encryptionMethod: '',
            showMessage: false
        });
        setValue([]);
        setRestoreFileName(false);
    };

    const handleUpload = () => {
        return decryptFile(value[0].blobFile.name, value[0].blobFile.type, value[0].blobFile, restoreFileName)
            .then((fileId) => {
                setUploadFileState({ ...uploadFileState, fileName: value[0].blobFile.name });
                return getDecryptUrl(fileId)
                    .then((response: any) => {
                        setUploadFileState((uploadFileState) => ({
                            ...uploadFileState,
                            url: response.url,
                            contacts: response.clients,
                            createDate: response.createDate,
                            creator: response.creator,
                            encryptionMethod: response.encryptionMethod,
                            showMessage: true
                        }));
                        setValue([]);
                        downloadFile(response.url);
                    })
                    .catch((err) => {
                        if (err === 'UNKNOWN_FILE') {
                            showErrorNotification(t('NOTIFICATION.FILE_COULD_NOT_DECRYPTED') as string);
                        } else {
                            showErrorNotification(t('NOTIFICATION.ERROR_NOTIFICATION') as string);
                        }
                    });
            })
            .catch((err) => {
                showErrorNotification(t('NOTIFICATION.ERROR_NOTIFICATION') as string);
            });
    };

    const handleUploadFileChange = (fileList: FileType[]) => {
        setUploadFileState({ ...uploadFileState, showMessage: false });
        if (fileList.length > 0) {
            const lastFile = fileList[fileList.length - 1];
            if (lastFile.blobFile && lastFile.blobFile.size > maxFileSize) {
                showErrorNotification(t('NOTIFICATION.THE_SELECTED_FILE_IS_TOO_LARGE') as string);
                setValue([]);
            } else {
                setValue([fileList[fileList.length - 1]]);
            }
        } else {
            setValue(fileList);
        }
    };

    const isUploaderValid = () => {
        return value.length === 1;
    };

    const handleNextUpload = () => {
        setUploadFileState({ ...uploadFileState, showMessage: false });
        setRestoreFileName(false);
    };

    const formatContactsList = (contacts: IContact[]): string[] => {
        return contacts
            .map((contact) => contact.email)
            .sort((a, b) => a.localeCompare(b, 'pl', { sensitivity: 'base' }));
    };

    return (
        <Modal
            open={props.openModalFileDecryption}
            onClose={handleCloseModalFileDecryption}
            onExited={handleExitModalDecryption}
            title={t('MODAL_FILE_DECRYPTION.HEADER')}
            body={
                <>
                    {!uploadFileState.showMessage && (
                        <>
                            <h5>{t('MODAL_FILE_DECRYPTION.UPLOAD_ENCRYPTED_FILE')}</h5>
                            <DecryptionUploaderWrapper>
                                <Uploader
                                    draggable
                                    fileList={value}
                                    autoUpload={false}
                                    onChange={handleUploadFileChange}
                                    multiple={false}
                                    /* Action jest wymagany, dlatego został ustawiony pusty string */
                                    action={''}
                                >
                                    <UploaderWrapper>
                                        <p>
                                            <a>{t('MODAL_FILE_DECRYPTION.CHOOSE_FILE')}</a>
                                            {t('MODAL_FILE_DECRYPTION.OR_DRAG_HERE')}
                                        </p>
                                    </UploaderWrapper>
                                </Uploader>
                            </DecryptionUploaderWrapper>
                            <h5>{t('MODAL_FILE_DECRYPTION.FILE_NAME')}</h5>
                            <Checkbox
                                checked={restoreFileName}
                                onChange={(value, checked) => {
                                    setRestoreFileName(checked);
                                }}
                            >
                                {t('MODAL_FILE_DECRYPTION.CHECKBOX_FILE_NAME')}
                            </Checkbox>
                            <GridButtons>
                                <Button
                                    appearance="primary"
                                    canClick={isUploaderValid}
                                    onClick={handleUpload}
                                    text={t('MODAL_FILE_DECRYPTION.DECRYPT_AND_DOWNLOAD_FILE')}
                                    disabled={!isUploaderValid()}
                                />
                            </GridButtons>
                        </>
                    )}
                    {uploadFileState.showMessage && (
                        <>
                            <SuccessMessage>
                                <img src="/assets/img/iconSuccess.svg" alt={t('ALT.SUCCESS_ICON') as string} />
                                <div>
                                    <h5>{t('MODAL_FILE_DECRYPTION.SUCCESS_MESSAGE_HEADER')}</h5>
                                    <p>
                                        {t('MODAL_FILE_DECRYPTION.SUCCESS_MESSAGE_TEXT')}
                                        <a href={uploadFileState.url}>
                                            {' '}
                                            {t('MODAL_FILE_DECRYPTION.SUCCESS_MESSAGE_LINK')}
                                        </a>
                                        .
                                    </p>
                                </div>
                            </SuccessMessage>
                            <ContactDetailsList
                                header={t('MODAL_FILE_DECRYPTION.RECIPIENT')}
                                details={formatContactsList(uploadFileState.contacts)}
                            />
                            <ContactDetails
                                header={t('MODAL_FILE_DECRYPTION.ENCRYPTOR')}
                                details={uploadFileState.creator}
                            />
                            <ContactDetails
                                header={t('MODAL_FILE_DECRYPTION.ENCRYPTION_DATE')}
                                details={formatDate(uploadFileState.createDate)}
                            />
                            <ContactDetails
                                header={t('MODAL_FILE_DECRYPTION.ENCRYPTION_METHOD')}
                                details={uploadFileState.encryptionMethod}
                            />
                            <GridButtons>
                                <Button
                                    onClick={handleNextUpload}
                                    text={t('MODAL_FILE_DECRYPTION.DECRYPT_NEXT_FILE')}
                                    appearance="primary"
                                />
                            </GridButtons>
                        </>
                    )}
                    <GridButtons>
                        <Button
                            appearance={'link'}
                            onClick={handleCloseModalFileDecryption}
                            text={t('COMMON.CANCEL')}
                        />
                    </GridButtons>
                </>
            }
        />
    );
};

export default ModalFileDecryption;
