import React from 'react';
import { Auth } from 'aws-amplify';
import { Button } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { ButtonWrapper, GoogleIconWrapper } from '../styles/ButtonGoogleStyles';

const ButtonGoogle = () => {
    const { t } = useTranslation();

    return (
        <ButtonWrapper>
            <Button onClick={() => Auth.federatedSignIn({ customProvider: 'SAML' })}>
                <GoogleIconWrapper>
                    <img src="/assets/img/iconGoogle.svg" alt={t('ALT.GOOGLE_ICON') as string} />
                </GoogleIconWrapper>
                {t('LOGIN_PAGE.SIGN_IN_WITH_GOOGLE')}
            </Button>
        </ButtonWrapper>
    );
};

export default ButtonGoogle;
