import styled from 'styled-components';

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #f5f9ff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > img {
        height: 70px;
        margin: 40px 20px;
    }

    @media (max-width: 767px) {
        justify-content: start;
    }
`;

export const BoxShadowWrapper = styled.div`
    box-shadow: 4px 3px 16px rgba(33, 62, 153, 0.2);
    border-radius: 8px;
    padding: 60px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > button,
    & > form > div > button {
        width: 300px;
    }

    & > form {
        margin-bottom: 20px;
    }
`;

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    & > form {
        margin: 20px 0;

        & > div > button {
            width: 300px;
            font-size: 16px;
        }
    }
`;

export const ResendLinkWrapper = styled.div`
    margin-top: 40px;

    & > button {
        font-weight: 600;
        padding: 0 0 3px 5px;
    }
`;

export const ResetPasswordErrorMessageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    & > button {
        width: 300px;
        margin: 20px 0;
    }
`;
