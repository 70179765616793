import styled from 'styled-components';

export const GridNavbarItems = styled.div`
    display: flex;
    align-items: center;
    font-weight: 700;
    color: #0d183c99;

    & > * {
        margin-right: 10px;
    }

    @media (max-width: 766px) {
        flex-direction: column;
        width: 100%;
    }
`;

export const GridNavbarItem = styled.div`
    display: flex;
    align-items: center;

    & > p {
        color: #0d183c99;
        font-weight: 700;
    }

    & > img {
        margin-right: 5px;
    }

    & > button {
        font-weight: 700;
    }
`;

export const GridMobileNavbar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    position: fixed;
    top: 0;
    right: -1000px;
    z-index: 1200;
    background: #fff;
    padding: 20px;
    height: 100%;
    box-shadow: 4px 3px 16px rgba(33, 62, 153, 0.2);
    transition: all 1s ease;

    ${({ show }) =>
        show &&
        `
    right: 0;
  `}

    & > button > svg {
        height: 25px;
        width: 25px;
        margin-bottom: 20px;
    }
`;

export const GridMobileNavbarItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;

    & > p {
        padding: 8px 12px;
        color: #0d183c99;
    }

    & > * {
        font-weight: 700;
    }

    & > p {
        padding: 8px 12px;
        color: #0d183c99;
    }
`;
