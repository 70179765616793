import React, { useEffect, useState } from 'react';
import { Checkbox, Form, Schema, Uploader } from 'rsuite';
import Modal from './Modal';
import { DisplayFlex, GridButtons, InformationMessage, SuccessMessage, UploaderWrapper } from '../styles/ModalStyles';
import Button from './Button';
import { showErrorNotification, showSuccessNotification } from '../services/notificationService';
import { getUrl, MAX_FILE_SIZE, uploadFile } from '../services/uploadServices';
import ContactDetails from './ContactDetails';
import EncryptionMethods from './EncryptionMethods';
import { formatDate, momentDate } from '../utils/dateUtils';
import { IContactState } from './ContactsTable';
import { TypeAttributes } from 'rsuite/esm/@types/common';
import { useTranslation } from 'react-i18next';
import { downloadFile } from '../services/downloadFile';
import { sendPassword } from '../services/passwordService';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

interface ModalUploadFileProps {
    contactState: IContactState;
    setContactState: (contactState: any) => void;
    setSmsMessageModal: (smsMessageModal: any) => void;
}

interface IUploadFileState {
    url: any;
    fileName: string;
    showMessage: boolean;
}

interface IFormValue {
    newFileName?: string;
}

const ModalUploadFile: React.FC<ModalUploadFileProps> = (props) => {
    const [value, setValue] = useState<any>([]);
    const [encryptionMethodState, setEncryptionMethodState] = useState<string>('aes256');
    const [clearFile, setClearFile] = useState<boolean>(false);
    const [uploadFileState, setUploadFileState] = useState<IUploadFileState>({
        url: '',
        fileName: '',
        showMessage: false
    });
    const [renameFileName, setRenameFileName] = useState<boolean>(false);

    const { t } = useTranslation();

    const { StringType } = Schema.Types;
    const model = Schema.Model({
        newFileName: StringType()
            .isRequired(t('COMMON.IS_REQUIRED') as string)
            .pattern(/^[^\\/:\*\?"<>\|]+$/, t('MODAL_UPLOAD_FILE.PATTERN') as string)
    });

    const [checkTrigger, setCheckTrigger] = React.useState<TypeAttributes.CheckTrigger>('change');
    const [formValue, setFormValue] = React.useState<IFormValue>({
        newFileName: t('MODAL_UPLOAD_FILE.DOCUMENT_') + momentDate()
    });
    const [formError, setFormError] = React.useState<IFormValue>({});

    const isSmsMessages = useSelector((state: RootState) => state.featureToggle.smsMessages);

    /* https://github.com/rsuite/rsuite/issues/1147 */
    const clearUploader = () => {
        setValue([]);
        setClearFile(true);
        setClearFile(false);
        setRenameFileName(false);
        setFormValue({
            newFileName: t('MODAL_UPLOAD_FILE.DOCUMENT_') + momentDate()
        });
    };

    //FIXME do testowania metody szyfrowania z hasłem jednorazowym - usunąc po wprowadzeniu formatki
    // ['88a4375f-9e6c-46d4-88a9-790474fb9bc1', '558ae08b-bf2f-4bb2-8347-41c0e1294b4b'],
    //   true,
    const handleUpload = () => {
        return uploadFile(
            value[0].blobFile.name,
            value[0].blobFile.type,
            value[0].blobFile,
            [props.contactState.contact.id],
            false,
            encryptionMethodState,
            renameFileName ? formValue.newFileName : null
        )
            .then((fileId) => {
                setUploadFileState({ ...uploadFileState, fileName: value[0].blobFile.name });
                return getUrl(fileId, props.contactState.contact.id)
                    .then((responseUrl: string) => {
                        setUploadFileState((uploadFileState) => ({
                            ...uploadFileState,
                            url: responseUrl,
                            showMessage: true
                        }));
                        downloadFile(responseUrl);
                        clearUploader();
                    })
                    .catch((err) => {
                        showErrorNotification(t('NOTIFICATION.ERROR_NOTIFICATION') as string);
                    });
            })
            .catch((err) => {
                showErrorNotification(t('NOTIFICATION.ERROR_NOTIFICATION') as string);
            });
    };

    const handleExitModalUploadFile = () => {
        props.setContactState({ contactId: null, showUploadFileModal: false });
        setUploadFileState({ url: '', fileName: '', showMessage: false });
        setValue([]);
        setRenameFileName(false);
        setFormValue({ newFileName: t('MODAL_UPLOAD_FILE.DOCUMENT_') + momentDate() });
        setFormError({});
    };

    const handleCloseModalUploadFile = () => {
        props.setContactState({ ...props.contactState, showUploadFileModal: false });
    };

    const handleEncryptNextFile = () => {
        setUploadFileState({ url: '', fileName: '', showMessage: false });
        clearUploader();
    };

    const handleUploadFileChange = (fileList: any) => {
        setUploadFileState({ ...uploadFileState, showMessage: false });
        if (fileList.length > 0) {
            const lastFile = fileList[fileList.length - 1];
            if (lastFile.blobFile.size > MAX_FILE_SIZE) {
                showErrorNotification(t('NOTIFICATION.THE_SELECTED_FILE_IS_TOO_LARGE') as string);
                setValue([]);
            } else {
                setValue([fileList[fileList.length - 1]]);
            }
        } else {
            setValue(fileList);
        }
    };

    const isUploaderValid = () => {
        if (renameFileName) {
            return value.length === 1 && Object.keys(formError).length === 0;
        }
        return value.length === 1;
    };

    const handleSendPasswordSubmit = () => {
        return sendPassword(props.contactState.contact.id, false)
            .then((response) => {
                if (!isSmsMessages) {
                    props.setSmsMessageModal({
                        openModal: true,
                        smsMessage: t('MODAL_SMS_MESSAGE.GENERATE_PASSWORD_MESSAGE'),
                        password: response.data.password
                    });
                }
                showSuccessNotification(t('NOTIFICATION.CURRENT_PASSWORD_SENT') as string);
                props.setContactState({
                    ...props.contactState,
                    contact: { ...props.contactState.contact, passwordLastSendDate: formatDate(response.data.sentDate) }
                });
            })
            .catch((err) => {
                showErrorNotification(t('NOTIFICATION.COULD_NOT_SEND_PASSWORD') as string);
            });
    };

    return (
        <Modal
            open={props.contactState.showUploadFileModal}
            onClose={handleCloseModalUploadFile}
            onExited={handleExitModalUploadFile}
            title={t('MODAL_UPLOAD_FILE.HEADER')}
            body={
                <>
                    <h5>{t('CONTACT.CONTACT_DETAILS')}</h5>
                    <DisplayFlex>
                        <ContactDetails header={t('CONTACT.EMAIL')} details={props.contactState.contact?.email} />
                        <ContactDetails header={t('CONTACT.PHONE')} details={props.contactState.contact?.phoneNumber} />
                    </DisplayFlex>
                    {props.contactState.contact?.description && (
                        <ContactDetails
                            header={t('CONTACT.DESCRIPTION')}
                            details={props.contactState.contact?.description}
                        />
                    )}
                    {!props.contactState.contact?.passwordLastSendDate && (
                        <InformationMessage>
                            <img src="/assets/img/iconInformation.svg" alt={t('ALT.INFORMATION_ICON') as string} />
                            <p>
                                {t('MODAL_UPLOAD_FILE.PASSWORD_INFORMATION_MESSAGE')}
                                <a onClick={handleSendPasswordSubmit}>{t('MODAL_UPLOAD_FILE.SEND_PASSWORD_ACTION')}</a>
                                {t('MODAL_UPLOAD_FILE.SEND_PASSWORD_MESSAGE')}
                            </p>
                        </InformationMessage>
                    )}
                    {!uploadFileState.showMessage && (
                        <>
                            <h5>{t('MODAL_UPLOAD_FILE.ENCRYPTION_METHOD')}</h5>
                            <EncryptionMethods
                                encryptionMethodState={encryptionMethodState}
                                setEncryptionMethodState={setEncryptionMethodState}
                            />

                            {!clearFile && (
                                <Uploader
                                    draggable
                                    fileList={value}
                                    autoUpload={false}
                                    onChange={handleUploadFileChange}
                                    multiple={false}
                                    /* Action jest wymagany, dlatego został ustawiony pusty string */
                                    action={''}
                                >
                                    <UploaderWrapper>
                                        <p>
                                            <a>{t('MODAL_UPLOAD_FILE.CHOOSE_FILE')}</a>
                                            {t('MODAL_UPLOAD_FILE.OR_DRAG_HERE')}
                                        </p>
                                    </UploaderWrapper>
                                </Uploader>
                            )}
                            <p style={{ textAlign: 'right', marginBottom: 10 }}>
                                {t('MODAL_UPLOAD_FILE.MAXIMUM_SIZE_MESSAGE')}
                            </p>
                            <h5>{t('MODAL_UPLOAD_FILE.FILE_NAME')}</h5>
                            <Checkbox
                                checked={renameFileName}
                                onChange={(value, checked) => {
                                    setRenameFileName(checked);
                                }}
                            >
                                {t('MODAL_UPLOAD_FILE.CHECKBOX_FILE_NAME')}
                            </Checkbox>
                            {renameFileName && (
                                <Form
                                    onChange={setFormValue}
                                    onCheck={setFormError}
                                    formError={formError}
                                    formDefaultValue={formValue}
                                    model={model}
                                    checkTrigger={checkTrigger}
                                >
                                    <Form.Group className="newFileName">
                                        <Form.ControlLabel>{t('MODAL_UPLOAD_FILE.NEW_FILE_NAME')}</Form.ControlLabel>
                                        <Form.Control name="newFileName" errorMessage={formError.newFileName} />
                                    </Form.Group>
                                </Form>
                            )}
                            <GridButtons>
                                <Button
                                    appearance="primary"
                                    canClick={isUploaderValid}
                                    onClick={handleUpload}
                                    text={t('MODAL_UPLOAD_FILE.ENCRYPT_FILE')}
                                    disabled={!isUploaderValid()}
                                />
                            </GridButtons>
                        </>
                    )}
                    {uploadFileState.showMessage && (
                        <>
                            <SuccessMessage>
                                <img src="/assets/img/iconSuccess.svg" alt={t('ALT.SUCCESS_ICON') as string} />
                                <div>
                                    <h5>{t('MODAL_UPLOAD_FILE.SUCCESS_MESSAGE_HEADER')}</h5>
                                    <p>
                                        {t('MODAL_UPLOAD_FILE.SUCCESS_MESSAGE_TEXT')}
                                        <a href={uploadFileState.url}> {t('MODAL_UPLOAD_FILE.SUCCESS_MESSAGE_LINK')}</a>
                                        .
                                    </p>
                                </div>
                            </SuccessMessage>
                            <GridButtons>
                                <Button
                                    appearance="primary"
                                    onClick={handleEncryptNextFile}
                                    text={t('ACTIONS.ENCRYPT_NEXT_FILE')}
                                />
                            </GridButtons>
                        </>
                    )}
                    <GridButtons>
                        <Button appearance={'link'} onClick={handleCloseModalUploadFile} text={t('COMMON.CANCEL')} />
                    </GridButtons>
                </>
            }
        />
    );
};

export default ModalUploadFile;
