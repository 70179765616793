import { createSlice } from '@reduxjs/toolkit';

interface featureToggleState {
    userCognitoLogin: boolean;
    smsMessages: boolean;
    demoNotification: boolean;
}

const initialState: featureToggleState = {
    userCognitoLogin: process.env.REACT_APP_AUTH_IDENTITY_PROVIDER === 'COGNITO',
    smsMessages: process.env.REACT_APP_FEATURE_SMS_MESSAGES === 'true',
    demoNotification: process.env.REACT_APP_FEATURE_DEMO_NOTIFICATION === 'true'
};

const featureToggleSlice = createSlice({
    name: 'featureToggle',
    initialState,
    reducers: {
        setUserCognitoLogin(state, action) {
            state.userCognitoLogin = action.payload.enable;
        },
        setSmsMessages(state, action) {
            state.smsMessages = action.payload.enable;
        },
        setDemoNotification(state, action) {
            state.demoNotification = action.payload.enable;
        }
    }
});

export const featureToggleActions = featureToggleSlice.actions;

export default featureToggleSlice.reducer;
