import React from 'react';
import { Container } from '../styles/DemoNotificationStyles';
import { useTranslation } from 'react-i18next';

interface DemoNotificationProps {
    header: string;
    message: string;
}

const DemoNotification: React.FC<DemoNotificationProps> = (props) => {
    const { t } = useTranslation();

    return (
        <Container>
            <img src="/assets/img/iconNotification.svg" alt={t('ALT.INFORMATION_ICON') as string} />
            <div>
                <h5>{props.header}</h5>
                <p>
                    {props.message}{' '}
                    <a href="https://kryptli.com/cennik/#formularz-zam%C3%B3wienia" target="_blank">
                        {t('DEMO_NOTIFICATION.CONTACT_LINK')}
                    </a>
                    .
                </p>
            </div>
        </Container>
    );
};

export default DemoNotification;
