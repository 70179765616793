import styled from 'styled-components';

export const UiBlockWrapper = styled.div`
    position: fixed;
    bottom: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1100;
    background: transparent;

    ${({ background }) =>
        background &&
        `
    background: #0015299e;
  `}
`;
