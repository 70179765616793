import styled from 'styled-components';

export const GridContactDetails = styled.div`
    width: 100%;
    margin-bottom: 20px;
`;

export const ContactTitleDetails = styled.p`
    color: #0d183c99;
`;
