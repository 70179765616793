import React, { useState } from 'react';
import { Button as ButtonRsuite } from 'rsuite';
import { uiBlockService } from '../services/uiBlockService';
import { TypeAttributes } from 'rsuite/esm/@types/common';

interface ButtonProps {
    appearance: string;
    onClick: () => void | Promise<any>;
    canClick?: () => boolean;
    text: string;
    disabled?: boolean;
    color?: string;
    type?: 'button' | 'reset' | 'submit';
}

const defaultProps = {
    appearance: 'primary',
    canClick: () => true,
    disabled: false,
    color: 'blue'
};

const Button: React.FC<ButtonProps> = (propsIn) => {
    const props = { ...defaultProps, ...propsIn };
    const [loading, setLoading] = useState(false);

    const startLoading = () => {
        setLoading(true);
        uiBlockService.block();
    };
    const stopLoading = () => {
        setLoading(false);
        uiBlockService.unBlock();
    };

    const handleOnClick = () => {
        if (props.canClick()) {
            startLoading();
            const result = props.onClick();

            if (result) {
                result.finally(() => stopLoading());
            } else {
                stopLoading();
            }
        }
    };

    return (
        <>
            <ButtonRsuite
                appearance={props.appearance as TypeAttributes.Appearance}
                onClick={handleOnClick}
                loading={loading}
                disabled={props.disabled}
                type={props.type}
            >
                {props.text}
            </ButtonRsuite>
        </>
    );
};

Button.defaultProps = defaultProps;

export default Button;
