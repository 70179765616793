import styled from 'styled-components';

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #f5f9ff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > img {
        margin: 40px 20px;
    }

    & > div {
        width: 775px;
    }

    @media (max-width: 767px) {
        justify-content: start;

        & > div {
            width: 388px;
        }
    }

    @media (max-width: 420px) {
        justify-content: start;

        & > div {
            width: 320px;
        }
    }
`;

export const PanelWrapper = styled.div`
    display: flex;
    box-shadow: 4px 3px 16px rgba(33, 62, 153, 0.2);
    border-radius: 8px;

    @media (max-width: 767px) {
        flex-direction: column-reverse;
        justify-content: start;
        margin-left: 20px;
        margin-right: 20px;
    }

    & > div {
        width: 50%;
        max-width: 415px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 60px 30px;

        @media (max-width: 767px) {
            width: 100%;
            padding: 30px 15px;
        }
    }
`;

export const TextWrapper = styled.div`
    background: #bbdffe;

    & > p {
        font-size: 16px;
        margin-bottom: 30px;
    }
`;

export const LoginWrapper = styled.div`
    & > h3 {
        margin-bottom: 15px;
    }

    & > form {
        margin-bottom: 15px;

        & > div > button {
            width: 300px;
            font-size: 16px;
        }
    }
`;

export const KryptliLink = styled.a`
    color: #0076db;
    text-decoration: underline;
`;
