import React, { useEffect, useState } from 'react';
import { ContentColumnCenter, ContentSpaceBetween } from '../../styles/PageStyles';
import { Button, Content, Grid, Toggle } from 'rsuite';
import ContactsTable, { IContactsTableState } from '../../components/ContactsTable';
import ModalNewContact from '../../components/ModalNewContact';
import { ContactTableSearchParameters, getContacts } from '../../services/contactService';
import { showErrorNotification, showSuccessNotification } from '../../services/notificationService';
import PlusIcon from '@rsuite/icons/Plus';
import Search from '../../components/Search';
import { ToggleSwitchWrapper } from '../../styles/ContactsTableStyles';
import { useDispatch, useSelector } from 'react-redux';
import { loaderActions } from '../../store/loader';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../store';
import DemoNotification from '../../components/DemoNotification';
import ModalSmsMessages from '../../components/ModalSmsMessages';

export interface IOpenModal {
    openNewContactModal: boolean;
}

export interface ISmsMessageModal {
    openModal: boolean;
    smsMessage: string;
    password: string;
}

const viewState = {
    INIT: 1,
    CONTACTS: 2,
    NO_CONTACTS: 3
};

const Dashboard = () => {
    const [openModal, setOpenModal] = useState<IOpenModal>({
        openNewContactModal: false
    });
    const [smsMessageModal, setSmsMessageModal] = useState<ISmsMessageModal>({
        openModal: false,
        smsMessage: '',
        password: ''
    });
    const [filterOn, setFilterOn] = useState<boolean>(false);
    const [filterString, setFilterString] = useState<string>('');
    const [tableState, setTableState] = useState<IContactsTableState>({
        loading: false,
        limit: 10,
        page: 1,
        data: [],
        showDisabled: false,
        viewState: viewState.INIT
    });

    const { t } = useTranslation();

    const isDemo = useSelector((state: RootState) => state.featureToggle.demoNotification);

    const dispatch = useDispatch();
    const showLoader = () => {
        dispatch(loaderActions.showLoader());
    };
    const hideLoader = () => {
        dispatch(loaderActions.hideLoader());
    };

    const clearFilter = () => {
        setFilterOn(false);
        setFilterString('');
    };
    const handleOpenNewContactModal = () => setOpenModal({ ...openModal, openNewContactModal: true });

    useEffect(() => {
        loadTableData({ limit: tableState.limit, showDisabled: false, resetPagePosition: true });
    }, []);

    const loadTableData = (tableSearchParameters: ContactTableSearchParameters) => {
        setTableState((tableState) => ({ ...tableState, loading: true }));
        showLoader();
        getContacts(tableSearchParameters)
            .then((data) => {
                hideLoader();
                setTableState((tableState) => ({
                    ...tableState,
                    page: tableSearchParameters.resetPagePosition ? 1 : tableState.page,
                    loading: false,
                    data: data.items,
                    lastKey: data.lastKey,
                    limit: tableSearchParameters.limit === null ? tableState.limit : tableSearchParameters.limit,
                    viewState: updateViewState(data, tableSearchParameters.filter ? tableSearchParameters.filter : '')
                }));
            })
            .catch((err) => {
                setTableState((tableState) => ({ ...tableState, loading: false }));
                showErrorNotification(t('NOTIFICATION.ERROR_NOTIFICATION') as string);
            });
    };

    const updateViewState = (data: any, filter: string) => {
        if (data.items.length === 0 && !(filter.trim().length >= 3)) {
            return viewState.NO_CONTACTS;
        } else {
            return viewState.CONTACTS;
        }
    };

    const addedNewContactSuccessfully = () => {
        showSuccessNotification(t('NOTIFICATION.NEW_CLIENT_ADDED') as string);
        loadTableData({ limit: tableState.limit, showDisabled: tableState.showDisabled, resetPagePosition: true });
        clearFilter();
    };

    const handleFiltering = () => {
        if (filterString.trim().length >= 3) {
            loadTableData({
                limit: null,
                filter: getFilterString(),
                showDisabled: tableState.showDisabled,
                resetPagePosition: true
            });
            setFilterOn(true);
        } else if (filterString.trim().length === 0) {
            loadTableData({ limit: tableState.limit, showDisabled: tableState.showDisabled, resetPagePosition: true });
            setFilterOn(false);
        }
    };

    const getFilterString = () => {
        const trimmedFilterString = filterString.trim();
        return trimmedFilterString.length >= 3 ? trimmedFilterString : undefined;
    };

    return (
        <>
            <Content>
                <Grid>
                    {isDemo && (
                        <DemoNotification
                            header={t('DEMO_NOTIFICATION.HEADER')}
                            message={t('DEMO_NOTIFICATION.TEXT')}
                        />
                    )}
                    {tableState.viewState === viewState.CONTACTS && (
                        <>
                            <ContentSpaceBetween>
                                <h3>{t('CONTACT.CONTACT_LIST')}</h3>
                                <Button
                                    appearance="primary"
                                    onClick={handleOpenNewContactModal}
                                    style={{ display: 'flex', alignItems: 'center' }}
                                >
                                    <PlusIcon style={{ marginRight: 5 }} />
                                    Dodaj nowy kontakt
                                </Button>
                            </ContentSpaceBetween>
                            <Search
                                filterString={filterString}
                                onChangeSearch={setFilterString}
                                handleFiltering={handleFiltering}
                                text={t('ACTIONS.SEARCH')}
                            />
                            <ToggleSwitchWrapper>
                                <label>{t('ACTIONS.SHOW_DEACTIVATED_CONTACTS')}</label>
                                <Toggle
                                    checked={tableState.showDisabled}
                                    onChange={(value) => {
                                        setTableState((tableState) => ({ ...tableState, showDisabled: value }));
                                        loadTableData({
                                            limit: tableState.limit,
                                            filter: getFilterString(),
                                            showDisabled: value,
                                            resetPagePosition: true
                                        });
                                    }}
                                />
                            </ToggleSwitchWrapper>
                            <ContactsTable
                                tableState={tableState}
                                setTableState={setTableState}
                                loadTableData={loadTableData}
                                filterOn={filterOn}
                                clearFilter={clearFilter}
                                smsMessageModal={smsMessageModal}
                                setSmsMessageModal={setSmsMessageModal}
                            />
                        </>
                    )}
                    {tableState.viewState === viewState.NO_CONTACTS && (
                        <>
                            <h3>{t('CONTACT.CONTACT_LIST')}</h3>
                            <ContentColumnCenter>
                                <img
                                    src="/assets/img/emptyContactList.svg"
                                    alt={t('ALT.EMPTY_CONTACT_LIST_ICON') as string}
                                    style={{ marginBottom: 20 }}
                                />
                                <h4>{t('CONTACT.EMPTY_CONTACT_LIST')}</h4>
                                <>
                                    <p style={{ marginBottom: 30 }}>{t('ACTIONS.ADD_FIRST_CONTACT')}</p>
                                    <Button
                                        appearance="primary"
                                        onClick={handleOpenNewContactModal}
                                        style={{ display: 'flex', alignItems: 'center' }}
                                    >
                                        <PlusIcon style={{ marginRight: 5 }} />
                                        {t('ACTIONS.ADD_NEW_CONTACT')}
                                    </Button>
                                </>
                            </ContentColumnCenter>
                        </>
                    )}
                </Grid>
            </Content>
            <ModalNewContact
                openModal={openModal}
                setOpenModal={setOpenModal}
                addedNewContactSuccessfully={addedNewContactSuccessfully}
                smsMessageModal={smsMessageModal}
                setSmsMessageModal={setSmsMessageModal}
            />
            <ModalSmsMessages smsMessageModal={smsMessageModal} setSmsMessageModal={setSmsMessageModal} />
        </>
    );
};

export default Dashboard;
