const AreaCodes = [
    {
        label: 'Polska +48',
        value: '+48'
    },
    {
        label: 'Austria +43',
        value: '+43'
    },
    {
        label: 'Belgia +32',
        value: '+32'
    },
    {
        label: 'Bułgaria +359',
        value: '+359'
    },
    {
        label: 'Chorwacja +385',
        value: '+385'
    },
    {
        label: 'Cypr +357',
        value: '+357'
    },
    {
        label: 'Czechy +420',
        value: '+420'
    },
    {
        label: 'Dania +45',
        value: '+45'
    },
    {
        label: 'Estonia +372',
        value: '+372'
    },
    {
        label: 'Finlandia +358',
        value: '+358'
    },
    {
        label: 'Francja +33',
        value: '+33'
    },
    {
        label: 'Grecja +30',
        value: '+30'
    },
    {
        label: 'Hiszpania +34',
        value: '+34'
    },
    {
        label: 'Holandia +31',
        value: '+31'
    },
    {
        label: 'Irlandia +353',
        value: '+353'
    },
    {
        label: 'Litwa +370',
        value: '+370'
    },
    {
        label: 'Luksemburg +352',
        value: '+352'
    },
    {
        label: 'Malta +356',
        value: '+356'
    },
    {
        label: 'Niemcy +49',
        value: '+49'
    },
    {
        label: 'Portugalia +351',
        value: '+351'
    },
    {
        label: 'Szwecja +46',
        value: '+46'
    },
    {
        label: 'Słowacja +421',
        value: '+421'
    },
    {
        label: 'Słowenia +386',
        value: '+386'
    },
    {
        label: 'Wielka Brytania +44',
        value: '+44'
    },
    {
        label: 'Węgry +36',
        value: '+36'
    },
    {
        label: 'Włochy +39',
        value: '+39'
    },
    {
        label: 'Łotwa +371',
        value: '+371'
    }
];

export default AreaCodes;
