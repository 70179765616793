import moment from 'moment';

export const formatDate = (date: string) => {
    if (!date) {
        return '';
    } else {
        return moment(date).format('DD.MM.YYYY HH:mm:ss');
    }
};

export const momentDate = () => {
    return moment().format('YYYYMMDD');
};
