import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { BoxShadowWrapper, Container } from '../../styles/ForgotPasswordStyles';
import SuccessMessage from '../../components/SuccessMessage';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../components/ErrorMessage';
import { loaderActions } from '../../store/loader';
import { useDispatch } from 'react-redux';
import { Link } from '../../styles/UtilsStyles';

const ConfirmUserPage = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const userName = searchParams.get('user_name');
    const confirmationCode = searchParams.get('confirmation_code');
    const [showSuccessMessage, setShowSuccessMessage] = React.useState<boolean>(false);
    const [showErrorMessage, setShowErrorMessage] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>('');

    const dispatch = useDispatch();
    const showLoader = () => {
        dispatch(loaderActions.showLoader());
    };
    const hideLoader = () => {
        dispatch(loaderActions.hideLoader());
    };

    useEffect(() => {
        confirmSignUp();
    }, []);

    const confirmSignUp = async () => {
        showLoader();
        try {
            if (typeof userName === 'string') {
                if (confirmationCode != null) {
                    await Auth.confirmSignUp(userName, confirmationCode);
                    setShowSuccessMessage(true);
                    hideLoader();
                }
            }
        } catch (err) {
            setShowErrorMessage(true);
            hideLoader();
            if (err) {
                if (err.toString().includes('UserNotFoundException')) {
                    setErrorMessage(t('REGISTER_CONFIRMATION.USER_NOT_FOUND_EXCEPTION') as string);
                } else if (err.toString().includes('NotAuthorizedException')) {
                    setErrorMessage(t('REGISTER_CONFIRMATION.NOT_AUTHORIZED_EXCEPTION') as string);
                } else if (err.toString().includes('LimitExceededException')) {
                    setErrorMessage(t('REGISTER_CONFIRMATION.LIMIT_EXCEEDED_EXCEPTION') as string);
                }
            } else {
                setErrorMessage(t('REGISTER_CONFIRMATION.ACCOUNT_VERIFICATION_FAILED_TEXT') as string);
            }
        }
    };

    return (
        <Container>
            <img src="/assets/img/logo.svg" alt={t('ALT.KRYPTLI_APP_LOGO') as string} />
            <BoxShadowWrapper>
                {showSuccessMessage && (
                    <>
                        <SuccessMessage
                            body={
                                <>
                                    <h5>{t('REGISTER_CONFIRMATION.SUCCESS_MESSAGE')}</h5>
                                    <p>{t('REGISTER_CONFIRMATION.SUCCESS_MESSAGE_TEXT')}</p>
                                </>
                            }
                        />
                        <Link href="/login">{t('COMMON.GO_BACK_LOGIN_PAGE')}</Link>
                    </>
                )}
                {showErrorMessage && (
                    <>
                        <ErrorMessage
                            body={
                                <>
                                    <h5>{t('REGISTER_CONFIRMATION.ACCOUNT_VERIFICATION_FAILED')}</h5>
                                    <p>{errorMessage}</p>
                                </>
                            }
                        />
                        <Link href="/login">{t('COMMON.GO_BACK_LOGIN_PAGE')}</Link>
                    </>
                )}
            </BoxShadowWrapper>
        </Container>
    );
};

export default ConfirmUserPage;
