import React from 'react';
import { TypeAttributes } from 'rsuite/esm/@types/common';
import { useTranslation } from 'react-i18next';
import { Form, Schema } from 'rsuite';
import LoginPanelWrapper from '../../components/LoginPanelWrapper';
import Button from '../../components/Button';
import { MarginBottom, Text, TextWrapper } from '../../styles/RegisterStyles';
import { Auth } from 'aws-amplify';
import { showErrorNotification, showSuccessNotification } from '../../services/notificationService';
import { Link } from '../../styles/UtilsStyles';
import { validatePassword } from '../../utils/passwordUtils';

export interface IUserState {
    userMail: string;
    password: string;
    showSuccessMessage: boolean;
}

const RegisterPage = () => {
    const formRef = React.useRef<any>();
    const [formValue, setFormValue] = React.useState<any>(null);
    const [formError, setFormError] = React.useState<any>({});
    const [checkTrigger, setCheckTrigger] = React.useState<TypeAttributes.CheckTrigger>('change');
    const [passwordErrorMessages, setPasswordErrorMessages] = React.useState<string>('');
    const [userState, setUserState] = React.useState<IUserState>({
        userMail: '',
        password: '',
        showSuccessMessage: false
    });

    const { t } = useTranslation();
    const { StringType } = Schema.Types;
    const model = Schema.Model({
        email: StringType()
            .isEmail(t('COMMON.IS_EMAIL') as string)
            .isRequired(t('COMMON.IS_REQUIRED') as string),
        password: StringType()
            .pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, passwordErrorMessages)
            .isRequired(t('COMMON.IS_REQUIRED') as string),
        repeatPassword: StringType()
            .addRule((value) => {
                return value === formValue.password;
            }, t('REGISTER.PASSWORDS_ARE_NOT_IDENTICAL') as string)
            .isRequired(t('COMMON.IS_REQUIRED') as string)
    });

    const handlePasswordChange = (value: string) => {
        const errorMessages = validatePassword(value, t);
        setPasswordErrorMessages(errorMessages);
    };

    const handleSubmit = async () => {
        if (!formRef.current.check()) {
            return;
        }
        try {
            const { user } = await Auth.signUp({
                username: formValue.email,
                password: formValue.password,
                attributes: {
                    email: formValue.email
                }
            });
            setUserState({ userMail: formValue.email, password: formValue.password, showSuccessMessage: true });
        } catch (err) {
            if (err) {
                if (err.toString().includes('UsernameExistsException')) {
                    showErrorNotification(t('REGISTER.USER_ALREADY_EXISTS') as string);
                }
            } else {
                showErrorNotification(t('REGISTER.ACCOUNT_CREATION_FAILED') as string);
            }
        }
    };

    const handleResendLink = async () => {
        try {
            await Auth.resendSignUp(userState.userMail);
            showSuccessNotification(t('REGISTER.CODE_RESENT_SUCCESSFULLY') as string);
        } catch (err) {
            if (err) {
                if (err.toString().includes('LimitExceededException')) {
                    showErrorNotification(t('NOTIFICATION.ATTEMPT_LIMIT_EXCEEDED') as string);
                }
            } else {
                showErrorNotification(t('REGISTER.FAILED_RESEND_MESSAGE') as string);
            }
        }
    };

    const isFormValid = () => {
        return (
            formValue &&
            formValue.email &&
            formValue.password &&
            formValue.repeatPassword &&
            !formError.password &&
            !formError.repeatPassword &&
            !formError.email
        );
    };

    const handleRegisterBack = () => {
        setUserState({ ...userState, showSuccessMessage: false });
    };

    return (
        <>
            <LoginPanelWrapper
                children={
                    <>
                        {!userState.showSuccessMessage && (
                            <>
                                <h3>{t('REGISTER.REGISTER')}</h3>
                                <Form
                                    ref={formRef}
                                    onChange={setFormValue}
                                    onCheck={setFormError}
                                    formError={formError}
                                    model={model}
                                    checkTrigger={checkTrigger}
                                >
                                    <Form.Group controlId="email">
                                        <Form.ControlLabel>{t('REGISTER.EMAIL')}</Form.ControlLabel>
                                        <Form.Control name="email" />
                                    </Form.Group>
                                    <Form.Group controlId="password">
                                        <Form.ControlLabel>{t('REGISTER.PASSWORD')}</Form.ControlLabel>
                                        <Form.Control
                                            name="password"
                                            type="password"
                                            autoComplete="off"
                                            onChange={handlePasswordChange}
                                            errorMessage={passwordErrorMessages}
                                            className="passwordFormControl"
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="repeatPassword">
                                        <Form.ControlLabel>{t('REGISTER.REPEAT_PASSWORD')}</Form.ControlLabel>
                                        <Form.Control name="repeatPassword" type="password" autoComplete="off" />
                                    </Form.Group>
                                    <Text>
                                        <span>
                                            {t('REGISTER.ACCEPTANCE_OF_TERMS_1')}
                                            <a href="https://kryptli.com/polityka-prywatnosci/">
                                                {t('REGISTER.PRIVACY_POLICY')}
                                            </a>
                                            .
                                        </span>
                                    </Text>
                                    <Form.Group>
                                        <Button
                                            appearance="primary"
                                            onClick={handleSubmit}
                                            text={t('REGISTER.REGISTER')}
                                            type="submit"
                                            disabled={!isFormValid()}
                                        />
                                    </Form.Group>
                                </Form>
                                <Link href="/login">{t('REGISTER.BACK_TO_LOGIN_PAGE')}</Link>
                            </>
                        )}
                        {userState.showSuccessMessage && (
                            <TextWrapper>
                                <h3>{t('REGISTER.CONFIRM_REGISTRATION')}</h3>
                                <MarginBottom>
                                    <p>
                                        {t('REGISTER.REGISTER_CONFIRMATION_MESSAGE1')}
                                        <strong>{userState.userMail}</strong>
                                        {t('REGISTER.REGISTER_CONFIRMATION_MESSAGE2')}
                                    </p>
                                </MarginBottom>
                                <MarginBottom>
                                    <p>
                                        {t('REGISTER.CALL_TO_ACTION')}
                                        <a href="https://kryptli.com/#contact" target="_blank">
                                            {t('REGISTER.CONTACT_LINK')}
                                        </a>
                                        !
                                    </p>
                                </MarginBottom>
                                <Button
                                    appearance={'primary'}
                                    onClick={handleResendLink}
                                    text={t('REGISTER.SEND_AGAIN')}
                                />
                                <Button appearance="link" onClick={handleRegisterBack} text={'Wróć do rejestracji'} />
                            </TextWrapper>
                        )}
                    </>
                }
            />
        </>
    );
};

export default RegisterPage;
