import React from 'react';
import { Notification, toaster } from 'rsuite';

export const showErrorNotification = (message: string) => {
    return toaster.push(
        <Notification duration={10000} type="error" header="Błąd" closable>
            {message}
        </Notification>,
        {
            placement: 'topEnd'
        }
    );
};

export const showSuccessNotification = (message: string, duration: number = 10000, header: string = 'Sukces') => {
    toaster.push(
        <Notification type="success" duration={duration} header={header} closable>
            {message}
        </Notification>,
        {
            placement: 'topEnd'
        }
    );
};

export const showInfoNotification = (header: string, duration: number = 5000) => {
    toaster.push(
        <Notification type="info" duration={duration} header={header} closable/>,
        {
            placement: 'topEnd'
        }
    );
};
