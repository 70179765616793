import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, PanelWrapper, KryptliLink, LoginWrapper, TextWrapper } from '../styles/LoginPanelWrapperStyles';
import DemoNotification from './DemoNotification';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

interface LoginPanelWrapperProps {
    children: React.ReactNode;
}

const LoginPanelWrapper: React.FC<LoginPanelWrapperProps> = (props) => {
    const { t } = useTranslation();
    const isDemo = useSelector((state: RootState) => state.featureToggle.demoNotification);

    return (
        <Container>
            <img src="/assets/img/logo.svg" alt={t('ALT.KRYPTLI_APP_LOGO') as string} style={{ height: 70 }} />
            {isDemo && (
                <DemoNotification header={t('DEMO_NOTIFICATION.HEADER2')} message={t('DEMO_NOTIFICATION.TEXT2')} />
            )}
            <PanelWrapper>
                <TextWrapper>
                    <p>
                        {t('LOGIN_PAGE.FILE_DECRYPTION_PAGE_TEXT')}
                        <KryptliLink href="/file-decryption">{t('LOGIN_PAGE.FILE_DECRYPTION_PAGE_LINK')}</KryptliLink>.
                    </p>
                    <p>
                        {t('LOGIN_PAGE.KRYPTLI_PAGE_TEXT')}
                        <KryptliLink href="https://kryptli.com/#how-it-works" target="_blank">
                            {t('LOGIN_PAGE.KRYPTLI_PAGE_LINK')}
                        </KryptliLink>
                        .
                    </p>
                    <img src="/assets/img/login.svg" alt={t('ALT.ENCRYPTION_AND_SECURE_DOCUMENT_TRANSFER') as string} />
                </TextWrapper>
                <LoginWrapper>{props.children}</LoginWrapper>
            </PanelWrapper>
        </Container>
    );
};

export default LoginPanelWrapper;
