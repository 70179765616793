import styled from 'styled-components';

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MessageBubble = styled.div`
    background-color: #e9e9eb;
    border-radius: 15px;
    padding: 10px;
    display: inline-block;
    max-width: 80%;
    position: relative;
    margin-left: 20px;
    margin-bottom: 40px;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        height: 25px;
        left: -6px;
        border-bottom-right-radius: 16px 14px;
        background-color: #e9e9eb;
        width: 15px;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 25px;
        left: -25px;
        border-bottom-right-radius: 10px;
        width: 25px;
        background-color: #fff;
    }
`;
