import React from 'react';
import Modal from './Modal';
import Button from './Button';
import { ButtonWrapper } from '../styles/ModalDeactivationStyles';
import { showErrorNotification, showSuccessNotification } from '../services/notificationService';
import { disableContact, getContacts } from '../services/contactService';
import { IContactsTableState, IContactState } from './ContactsTable';
import { useTranslation } from 'react-i18next';

interface ModalDeactivationProps {
    tableState: IContactsTableState;
    setTableState: (tableState: any) => void;
    contactState: IContactState;
    setContactState: (contactState: any) => void;
    clearFilter: () => void;
}

const ModalDeactivation: React.FC<ModalDeactivationProps> = (props) => {
    const handleCloseModalDeactivation = () => {
        props.setContactState({ ...props.contactState, showDeactivationModal: false });
    };

    const { t } = useTranslation();

    const deactivationContact = () => {
        return disableContact(props.contactState.contact.id)
            .then(() => {
                props.setContactState({ ...props.contactState, showDeactivationModal: false });
                props.setTableState((tableState: IContactsTableState) => ({ ...tableState, loading: true }));
                props.clearFilter();

                getContacts({
                    limit: props.tableState.limit,
                    lastKey: props.tableState.lastKey,
                    showDisabled: props.tableState.showDisabled,
                    resetPagePosition: true
                })
                    .then((data) => {
                        props.setTableState((tableState: IContactsTableState) => ({
                            ...tableState,
                            loading: false,
                            data: data.items,
                            lastKey: data.lastKey,
                            limit: tableState.limit === null ? tableState.limit : tableState.limit
                        }));
                        showSuccessNotification(t('NOTIFICATION.CONTACT_DEACTIVATED') as string);
                    })
                    .catch((err) => {
                        props.setTableState((tableState: IContactsTableState) => ({ ...tableState, loading: false }));
                        showErrorNotification(t('NOTIFICATION.ERROR_NOTIFICATION') as string);
                    });
            })
            .catch((err) => {
                showErrorNotification(t('NOTIFICATION.ERROR_NOTIFICATION') as string);
            });
    };

    return (
        <Modal
            open={props.contactState.showDeactivationModal}
            onClose={handleCloseModalDeactivation}
            title={t('MODAL_DEACTIVATION.HEADER')}
            body={<p>{t('MODAL_DEACTIVATION.MESSAGE')}</p>}
            footer={
                <ButtonWrapper>
                    <Button appearance="link" text={t('COMMON.CANCEL')} onClick={handleCloseModalDeactivation} />
                    <Button
                        appearance="primary"
                        color="red"
                        text={t('MODAL_DEACTIVATION.DEACTIVATE')}
                        onClick={deactivationContact}
                    />
                </ButtonWrapper>
            }
        />
    );
};

export default ModalDeactivation;
