import React, { useEffect, useState } from 'react';
import { UiBlockWrapper } from '../styles/UiBlockStyles';
import { useSelector } from 'react-redux';
import { UiBlockState } from '../store/uiBlock';
import { RootState } from '../store';

const UiBlock = () => {
    const [animateBackground, setAnimateBackground] = useState<boolean>(false);
    const uiBlockState = useSelector((state: RootState): UiBlockState => state.uiBlock);

    useEffect(() => {
        if (uiBlockState.isBlock && uiBlockState.background) {
            setTimeout(() => {
                setAnimateBackground(true);
            }, 500);
        } else if (!uiBlockState.isBlock) {
            setAnimateBackground(false);
        }
    }, [uiBlockState]);

    return <>{uiBlockState.isBlock && <UiBlockWrapper background={animateBackground} />}</>;
};

export default UiBlock;
