import Modal from './Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ISmsMessageModal } from '../pages/dashboard/Dashboard';
import { FlexColumn, MessageBubble } from '../styles/ModalSmsMessagesStyles';
import Button from './Button';

interface ModalSmsMessagesProps {
    smsMessageModal: ISmsMessageModal;
    setSmsMessageModal: (smsMessageModal: any) => void;
}

const ModalSmsMessages: React.FC<ModalSmsMessagesProps> = (props) => {
    const { t } = useTranslation();

    const handleCloseModal = () => {
        props.setSmsMessageModal({ openModal: false, smsMessage: '' });
    };

    return (
        <Modal
            open={props.smsMessageModal.openModal}
            onClose={handleCloseModal}
            title={t('MODAL_SMS_MESSAGE.HEADER')}
            body={
                <FlexColumn>
                    <MessageBubble>
                        <p>
                            <strong>{t('MODAL_SMS_MESSAGE.COMPANY_NAME')}</strong>
                            {props.smsMessageModal.smsMessage}
                            <strong>{props.smsMessageModal.password}</strong>
                        </p>
                    </MessageBubble>
                    <Button appearance={'primary'} text={'OK'} onClick={() => handleCloseModal()} />
                </FlexColumn>
            }
        />
    );
};

export default ModalSmsMessages;
