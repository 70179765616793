import styled from 'styled-components';

export const Link = styled.a`
    color: #1675e0 !important;
    text-decoration: none;
    font-weight: 700;
    font-size: 12px;

    &:hover {
        text-decoration: none;
    }
`;


