import styled from 'styled-components';

export const Container = styled.div`
    background: #ff6f0033;
    display: flex;
    max-width: 450px;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 6px;

    & > img {
        margin-right: 20px;
    }
`;
