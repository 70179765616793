import axios from 'axios';
import { getAuthorizationBearer } from './authService';
import { API } from 'aws-amplify';

interface IUploadParams {
    'file-name': string;
    'client-ids': Array<string>;
    'one-time-password': boolean;
    'encryption-method'?: string;
    'new-file-name'?: string;
}

interface IDownloadParams {
    'file-id': string;
    'client-id'?: string;
}

//32 kilobytes for encryption metadata
export const MAX_FILE_SIZE = 1024 * 1024 * 25 - 1024 * 32;

//contactIds: lista stringów z id kontaktów , w przypadku pojedynczego klienta wyusyłamy listę z pojedynczym stringiem
//oneTimePassword: true w przypadku gdy jest to odszyfrowanie z formatki do szyfrowania do szyfrowania z jednorazowym hasłem
export const uploadFile = async (
    fileName: string,
    fileType: string,
    file: File,
    contactIds: Array<string>,
    oneTimePassword: boolean,
    encryptionMethod?: string | null,
    newFileName?: string | null
) => {
    const uploadParams: IUploadParams = {
        'file-name': encodeURIComponent(fileName),
        'client-ids': contactIds,
        'one-time-password': oneTimePassword
    };

    encryptionMethod != null && (uploadParams['encryption-method'] = encryptionMethod);
    newFileName != null && (uploadParams['new-file-name'] = newFileName);

    return getUploadSignedUrl(uploadParams).then((response) => {
        return uploadFileViaUrl(response.url, file)
            .then(() => response.fileId)
            .catch((err) => {
                throw err;
            });
    });
};

const getUploadSignedUrl = async (params: IUploadParams) => {
    const getInit = {
        headers: {
            Authorization: await getAuthorizationBearer()
        },
        response: true,
        queryStringParameters: {
            ...params
        }
    };
    return API.get('DocumentSafeAPI', '/file/actions/encrypt/upload', getInit).then((response) => response.data);
};

const uploadFileViaUrl = async (signedUrl: string, file: File) => {
    return axios.put(signedUrl, file);
};

export const getUrl = async (fileId: string, contactId?: string): Promise<string> => {
    const downloadParams: IDownloadParams = {
        'file-id': fileId,
        'client-id': contactId
    };

    const getUrlWithTimeout = async (
        downloadParams: IDownloadParams,
        resolve: (response: string) => void,
        reject: (err?: any) => void
    ) => {
        setTimeout(() => {
            getDownloadSignedUrl(downloadParams)
                .then((response) => {
                    const { fileState } = response;
                    if (fileState === 'DONE') {
                        resolve(response.url);
                    } else if (fileState === 'ERROR') {
                        reject();
                    } else {
                        getUrlWithTimeout(downloadParams, resolve, reject);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        }, 1000);
    };

    return new Promise((resolve, reject) => {
        getUrlWithTimeout(downloadParams, resolve, reject);
    });
};

const getDownloadSignedUrl = async (params: any) => {
    const getInit = {
        headers: {
            Authorization: await getAuthorizationBearer()
        },
        response: true,
        queryStringParameters: {
            ...params
        }
    };
    const signedUrl = await API.get('DocumentSafeAPI', '/file/actions/encrypt/download', getInit)
        .then((response) => response.data)
        .catch((err) => console.error(err));
    if (!signedUrl) {
        throw new Error('Cannot get download signed url.');
    }
    return signedUrl;
};
