import React from 'react';
import { Input, InputGroup, Tooltip, Whisper } from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';
import { useTranslation } from 'react-i18next';

interface SearchProps {
    filterString: string;
    onChangeSearch: (value: string) => void;
    text: string;
    handleFiltering: () => void | Promise<any>;
}

const Search: React.FC<SearchProps> = (props) => {
    const { t } = useTranslation();

    const handleFilteringEnter = (event: KeyboardEvent | any) => {
        if (event.key === 'Enter') {
            props.handleFiltering();
        }
    };

    return (
        <Whisper trigger="focus" speaker={<Tooltip>{t('SEARCH.REQUIREMENT')}</Tooltip>}>
            <InputGroup style={{ maxWidth: 400, marginBottom: 20 }}>
                <Input
                    value={props.filterString}
                    onChange={(value) => props.onChangeSearch(value)}
                    onKeyDown={handleFilteringEnter}
                    placeholder={props.text}
                />
                <InputGroup.Button onClick={props.handleFiltering}>
                    <SearchIcon />
                </InputGroup.Button>
            </InputGroup>
        </Whisper>
    );
};

export default Search;
