import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserContextProvider } from './context/UserContext';
import { Amplify } from 'aws-amplify';
import { CustomProvider } from 'rsuite';
import pl_PL from './locales/pl_PL';
import { Provider } from 'react-redux';
import store from './store';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

Amplify.configure({
    API: {
        endpoints: [
            {
                name: 'DocumentSafeAPI',
                endpoint: process.env.REACT_APP_API_ENDPOINT
            }
        ]
    },
    Auth: {
        region: process.env.REACT_APP_AUTH_REGION,
        userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
        oauth: {
            domain: process.env.REACT_APP_AUTH_OAUTH_DOMAIN,
            scope: ['phone', 'email', 'profile', 'openid'],
            redirectSignIn: process.env.REACT_APP_AUTH_OAUTH_REDIRECT_SIGN_IN,
            redirectSignOut: process.env.REACT_APP_AUTH_OAUTH_REDIRECT_SIGN_OUT,
            responseType: 'code'
        }
    }
});

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'pl',
    resources: {
        pl: {
            translation: pl_PL
        }
    }
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <UserContextProvider>
            <CustomProvider locale={pl_PL}>
                <I18nextProvider i18n={i18next}>
                    <Provider store={store}>
                        <App />
                    </Provider>
                </I18nextProvider>
            </CustomProvider>
        </UserContextProvider>
    </React.StrictMode>
);

reportWebVitals();
