import styled from 'styled-components';

export const PaginationWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px 0;

    & > p {
        width: auto;
        margin-right: 10px;
    }
`;

export const ToggleSwitchWrapper = styled.div`
    margin: 20px 0;

    & > label {
        margin-right: 10px;
        font-weight: 500;
    }
`;
