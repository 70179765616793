import React, { useEffect } from 'react';
import { Form, Input, Schema } from 'rsuite';
import Modal from './Modal';
import Button from './Button';
import { GridButtons, GridFormInputs, RequiredIndicator, TextareaWrapper } from '../styles/ModalStyles';
import { showErrorNotification, showSuccessNotification } from '../services/notificationService';
import { editContact, getContacts } from '../services/contactService';
import { IContactsTableState, IContactState } from './ContactsTable';
import { TypeAttributes } from 'rsuite/esm/@types/common';
import { ITableState } from '../model/TableHelpers';
import Contact from '../model/Contact';
import { useTranslation } from 'react-i18next';

interface ModalEditContactProps {
    tableState: IContactsTableState;
    setTableState: (tableState: any) => void;
    contactState: IContactState;
    setContactState: (contactState: any) => void;
    clearFilter: () => void;
}

const Textarea = React.forwardRef<any>((props, ref) => <Input {...props} as="textarea" rows={4} ref={ref} />);

const ModalEditContact: React.FC<ModalEditContactProps> = (props) => {
    const { t } = useTranslation();
    const { StringType } = Schema.Types;
    const model = Schema.Model({
        email: StringType()
            .isEmail(t('COMMON.IS_EMAIL') as string)
            .isRequired(t('COMMON.IS_REQUIRED') as string)
    });

    const formRef = React.useRef<any>();
    const [checkTrigger, setCheckTrigger] = React.useState<TypeAttributes.CheckTrigger>('change');
    const [formValue, setFormValue] = React.useState<any>(null);
    const [formError, setFormError] = React.useState<any>({});

    const handleCloseModalEdit = () => {
        props.setContactState({ ...props.contactState, showEditModal: false });
        setFormValue(null);
    };

    const isFormValid = () => {
        return !!formRef.current && formRef.current.check() && Object.keys(formError).length === 0;
    };

    const disabledClickSave = () => {
        return (
            (formValue.email === props.contactState.contact.email &&
                formValue.description === props.contactState.contact.description) ||
            Object.keys(formError).length > 0
        );
    };

    useEffect(() => {
        if (props.contactState.showEditModal && props.contactState.contact) {
            setFormValue({
                email: props.contactState.contact.email,
                phoneNumber: props.contactState.contact.phoneNumber,
                description: props.contactState.contact.description
            });
        }
    }, [props.contactState.contact]);

    const saveChanges = () => {
        return editContact(props.contactState.contact.id, formValue.email, formValue.description)
            .then(() => {
                handleCloseModalEdit();
                props.setTableState((tableState: ITableState<Contact>) => ({ ...tableState, loading: true }));
                props.clearFilter();
                getContacts({
                    limit: props.tableState.limit,
                    lastKey: props.tableState.lastKey,
                    showDisabled: props.tableState.showDisabled,
                    resetPagePosition: true
                })
                    .then((data) => {
                        props.setTableState((tableState: ITableState<Contact>) => ({
                            ...tableState,
                            loading: false,
                            data: data.items,
                            lastKey: data.lastKey,
                            limit: tableState.limit === null ? tableState.limit : tableState.limit
                        }));
                        showSuccessNotification(t('NOTIFICATION.CONTACT_DETAILS_CHANGED') as string);
                    })
                    .catch((err) => {
                        props.setTableState((tableState: ITableState<Contact>) => ({ ...tableState, loading: false }));
                        showErrorNotification(t('NOTIFICATION.ERROR_NOTIFICATION') as string);
                    });
            })
            .catch((err) => {
                showErrorNotification(t('NOTIFICATION.ERROR_NOTIFICATION') as string);
            });
    };

    return (
        <>
            {formValue && (
                <Modal
                    open={props.contactState.showEditModal}
                    onClose={handleCloseModalEdit}
                    title={t('MODAL_EDIT_CONTACT.HEADER')}
                    body={
                        <Form
                            ref={formRef}
                            onChange={setFormValue}
                            onCheck={setFormError}
                            formError={formError}
                            formDefaultValue={formValue}
                            model={model}
                            checkTrigger={checkTrigger}
                        >
                            <GridFormInputs>
                                <Form.Group>
                                    <Form.ControlLabel className="modalEditContact">
                                        <RequiredIndicator>*</RequiredIndicator>
                                        {t('CONTACT.EMAIL')}
                                    </Form.ControlLabel>
                                    <Form.Control name="email" errorMessage={formError.email} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.ControlLabel className="modalEditContact">
                                        <RequiredIndicator>*</RequiredIndicator>
                                        {t('CONTACT.PHONE')}
                                    </Form.ControlLabel>
                                    <Form.Control
                                        name="phoneNumber"
                                        errorMessage={formError.phoneNumber}
                                        disabled={true}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.ControlLabel className="modalEditContact">
                                        {t('CONTACT.DESCRIPTION')}
                                    </Form.ControlLabel>
                                    <TextareaWrapper>
                                        <Form.Control
                                            name="description"
                                            accepter={Textarea}
                                            style={{ width: '100%' }}
                                        />
                                    </TextareaWrapper>
                                </Form.Group>
                            </GridFormInputs>
                            <Form.Group>
                                <GridButtons>
                                    <Button
                                        onClick={saveChanges}
                                        canClick={isFormValid}
                                        disabled={disabledClickSave()}
                                        text={t('MODAL_EDIT_CONTACT.SAVE_CHANGES')}
                                        appearance="primary"
                                    />
                                    <Button
                                        appearance={'link'}
                                        onClick={handleCloseModalEdit}
                                        text={t('COMMON.CANCEL')}
                                    />
                                </GridButtons>
                            </Form.Group>
                        </Form>
                    }
                />
            )}
        </>
    );
};

export default ModalEditContact;
