import styled from 'styled-components';

export const DisplayFlex = styled.div`
    display: flex;
`;

export const GridFormInputs = styled.div`
    margin-bottom: 20px;
`;

export const GridInputs = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > input {
        margin: 10px;
    }

    & > div {
        margin: 10px;
    }
`;

export const GridButtons = styled.div`
    width: 100%;
    display: inline-grid;

    & > button {
        margin: 5px 0 !important;
    }
`;

export const TagPickerWrapper = styled.div`
    & > div {
        width: 100%;
        margin-bottom: 20px;
    }
`;

export const TextareaWrapper = styled.div`
    & > div {
        width: 100%;
    }
`;

export const UploaderWrapper = styled.div`
    height: 70px;
    line-height: 40px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    border-color: #0076db !important;
    background: #0076db1a !important;
`;

export const DecryptionUploaderWrapper = styled.div`
    margin: 20px 0;
`;

export const SuccessMessage = styled.div`
    background: #08979c33;
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 6px;

    & > img {
        margin-right: 20px;
    }
`;

export const InformationMessage = styled.div`
    background: #ff6f0033;
    display: flex;
    margin-top: 10px;
    padding: 20px;
    border-radius: 6px;
    margin-bottom: 20px;

    & > img {
        margin-right: 20px;
    }
`;

export const RequiredIndicator = styled.span`
    color: #f5222d;
    margin-right: 3px;
`;

export const TutorialStepWrapper = styled.div`
    display: flex;
    margin-bottom: 20px;

    & > div {
        margin-right: 20px;
    }

    & > p {
        width: 80%;
    }
`;

export const IconStep = styled.div`
    background: #0050b3;
    height: 30px;
    width: 30px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 600;
`;

export const TutorialInfoText = styled.p`
    padding-bottom: 20px;
`;

export const MarginBottom = styled.div`
    margin-bottom: 20px;
`;
