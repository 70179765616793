import React from 'react';
import { Container } from '../styles/SuccessMeassageStyles';
import { useTranslation } from 'react-i18next';

interface SuccessMessageProps {
    body?: React.ReactNode;
}
const SuccessMessage: React.FC<SuccessMessageProps> = (props) => {
    const { t } = useTranslation();
    return (
        <Container>
            <img src="/assets/img/iconSuccess.svg" alt={t('ALT.SUCCESS_ICON') as string} />
            <div>{props.body}</div>
        </Container>
    );
};

export default SuccessMessage;
