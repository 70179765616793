import React, { useState } from 'react';
import { Button, Grid } from 'rsuite';
import { Header } from '../styles/NavbarStyles';
import NavbarItems from './NavbarItems';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import MenuIcon from '@rsuite/icons/Menu';
import { uiBlockService } from '../services/uiBlockService';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
    const [showNavbar, setShowNavbar] = useState<boolean>(false);
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });

    const { t } = useTranslation();

    const handleShowNavbar = () => {
        setShowNavbar(true);
        uiBlockService.blockWithBackground();
    };

    return (
        <>
            <Header>
                <Grid>
                    <Link to="/">
                        <img src="/assets/img/logo.svg" alt={t('ALT.KRYPTLI_APP_LOGO') as string} />
                    </Link>
                    {isDesktopOrLaptop && <NavbarItems showNavbar={showNavbar} setShowNavbar={setShowNavbar} />}
                    {isTabletOrMobile && (
                        <>
                            <Button appearance="link" onClick={handleShowNavbar}>
                                <MenuIcon />
                            </Button>
                            <NavbarItems showNavbar={showNavbar} setShowNavbar={setShowNavbar} />
                        </>
                    )}
                </Grid>
            </Header>
        </>
    );
};

export default Navbar;
