import React, { useState } from 'react';
import { Table } from 'rsuite';
import ModalPassword from './ModalPassword';
import ModalUploadFile from './ModalUploadFile';
import { getContact, ContactTableSearchParameters } from '../services/contactService';
import { showErrorNotification } from '../services/notificationService';
import { formatDate } from '../utils/dateUtils';
import ContactActions from './ContactActions';
import Button from './Button';
import { ContentTableActionButton } from '../styles/PageStyles';
import { useNavigate } from 'react-router-dom';
import ModalDeactivation from './ModalDeactivation';
import ModalEditContact from './ModalEditContact';
import { useDispatch } from 'react-redux';
import { loaderActions } from '../store/loader';
import Pagination from './Pagination';
import { ITableState } from '../model/TableHelpers';
import Contact from '../model/Contact';
import { useTranslation } from 'react-i18next';
import { ISmsMessageModal } from '../pages/dashboard/Dashboard';

interface ContactsTableProps {
    tableState: IContactsTableState;
    setTableState: (tableState: any) => void;
    loadTableData: (tableSearchParameters: ContactTableSearchParameters) => void;
    filterOn: boolean;
    clearFilter: () => void;
    smsMessageModal: ISmsMessageModal;
    setSmsMessageModal: (smsMessageModal: any) => void;
}

export interface IContactsTableState extends ITableState<Contact> {
    showDisabled: boolean;
    viewState: number;
}

export interface IContactState {
    contact: Contact;
    showModal: boolean;
    showUploadFileModal: boolean;
    showDeactivationModal: boolean;
    showEditModal: boolean;
}

const ContactsTable: React.FC<ContactsTableProps> = (props) => {
    const [contactState, setContactState] = useState<IContactState>({
        contact: {} as Contact,
        showModal: false,
        showUploadFileModal: false,
        showDeactivationModal: false,
        showEditModal: false
    });
    const { t } = useTranslation();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const loaderOn = () => {
        dispatch(loaderActions.showLoader());
    };
    const loaderOff = () => {
        dispatch(loaderActions.hideLoader());
    };

    const isContactDisabled = (rowData: any) => {
        return rowData.clientState === 'disabled';
    };

    const onHistoryClick = (data: any) => {
        navigate(`history/${data.id}`);
    };

    const onPasswordClick = (data: any) => {
        getContactAndUpdateState(data, (contact: any) => {
            contact.item.passwordLastSendDate = formatDate(contact.item.passwordLastSendDate);
            contact.item.passwordLastGenerateDate = formatDate(contact.item.passwordLastGenerateDate);
            setContactState({ ...contactState, contact: contact.item, showModal: true });
        });
    };

    const onUploadFileClick = (data: any) => {
        getContactAndUpdateState(data, (contact: any) =>
            setContactState({ ...contactState, contact: contact.item, showUploadFileModal: true })
        );
    };

    const onDeactivationClick = (data: any) => {
        getContactAndUpdateState(data, (contact: any) =>
            setContactState({ ...contactState, contact: contact.item, showDeactivationModal: true })
        );
    };

    const onEditContactClick = (data: any) => {
        getContactAndUpdateState(data, (contact: any) =>
            setContactState({ ...contactState, contact: contact.item, showEditModal: true })
        );
    };

    const getContactAndUpdateState = (data: any, setContactState: any) => {
        loaderOn();
        getContact(data.id)
            .then((contact) => {
                loaderOff();
                setContactState(contact);
            })
            .catch(() => {
                loaderOff();
                showErrorNotification(t('NOTIFICATION.ERROR_NOTIFICATION') as string);
            });
    };

    const handleChangeLimit = (limit: number) => {
        props.setTableState((tableState: IContactsTableState) => ({ ...tableState, limit: limit, page: 1 }));
        props.loadTableData({ limit: limit, showDisabled: props.tableState.showDisabled, resetPagePosition: true });
    };

    const handleChangePage = (page: number) => {
        if (props.tableState.page === page) {
            return;
        }
        props.setTableState((tableState: IContactsTableState) => ({ ...tableState, page: page }));
        props.loadTableData({
            limit: props.tableState.limit,
            lastKey: props.tableState.lastKey,
            showDisabled: props.tableState.showDisabled,
            resetPagePosition: false
        });
    };

    return (
        <div>
            <Table
                autoHeight={true}
                loading={props.tableState.loading}
                data={props.tableState.data}
                wordWrap
                rowClassName={(row) => (row && isContactDisabled(row) ? 'rowDisabled' : '')}
            >
                <Table.Column width={70}>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.Cell>
                        {(rowData) => (
                            <span>
                                {rowData.clientState === 'active' && (
                                    <img
                                        src="/assets/img/iconActive.svg"
                                        alt={t('ALT.ACTIVE_CONTACT_ICON') as string}
                                    />
                                )}
                                {rowData.clientState === 'disabled' && (
                                    <img
                                        src="/assets/img/iconDeactivate.svg"
                                        alt={t('ALT.DEACTIVATED_CONTACT_ICON') as string}
                                    />
                                )}
                            </span>
                        )}
                    </Table.Cell>
                </Table.Column>
                <Table.Column width={280}>
                    <Table.HeaderCell>{t('CONTACT.EMAIL')}</Table.HeaderCell>
                    <Table.Cell dataKey="email" />
                </Table.Column>

                <Table.Column width={180}>
                    <Table.HeaderCell>{t('CONTACT.PHONE')}</Table.HeaderCell>
                    <Table.Cell dataKey="phoneNumber" />
                </Table.Column>

                <Table.Column width={500}>
                    <Table.HeaderCell>{t('CONTACT.DESCRIPTION')}</Table.HeaderCell>
                    <Table.Cell dataKey="description" />
                </Table.Column>
                <Table.Column width={160} fixed="right">
                    <Table.HeaderCell>Akcje</Table.HeaderCell>
                    <Table.Cell id="control-id-1">
                        {(rowData) => {
                            return (
                                <ContentTableActionButton>
                                    <Button
                                        appearance="link"
                                        onClick={() => onUploadFileClick(rowData)}
                                        text={t('ACTIONS.ENCRYPT_FILE')}
                                        disabled={isContactDisabled(rowData)}
                                    />
                                    <ContactActions
                                        handleOnClickPassword={() => onPasswordClick(rowData)}
                                        handleOnClickHistory={() => onHistoryClick(rowData)}
                                        handleOnClickDeactivate={() => onDeactivationClick(rowData)}
                                        handleOnClickEdit={() => onEditContactClick(rowData)}
                                        isContactDisabled={isContactDisabled(rowData)}
                                    />
                                </ContentTableActionButton>
                            );
                        }}
                    </Table.Cell>
                </Table.Column>
            </Table>
            {!props.filterOn && (
                <Pagination
                    tableState={props.tableState}
                    setTableState={props.setTableState}
                    handleChangePage={handleChangePage}
                    handleChangeLimit={handleChangeLimit}
                />
            )}
            <ModalPassword
                contactState={contactState}
                setContactState={setContactState}
                smsMessageModal={props.smsMessageModal}
                setSmsMessageModal={props.setSmsMessageModal}
            />
            <ModalUploadFile
                contactState={contactState}
                setContactState={setContactState}
                setSmsMessageModal={props.setSmsMessageModal}
            />
            <ModalDeactivation
                contactState={contactState}
                setContactState={setContactState}
                tableState={props.tableState}
                setTableState={props.setTableState}
                clearFilter={props.clearFilter}
            />
            <ModalEditContact
                contactState={contactState}
                setContactState={setContactState}
                tableState={props.tableState}
                setTableState={props.setTableState}
                clearFilter={props.clearFilter}
            />
        </div>
    );
};

export default ContactsTable;
