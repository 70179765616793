import axios from 'axios';
import { getAuthorizationBearer } from './authService';
import { API } from 'aws-amplify';

interface UploadParams {
    'file-name': string;
    'restore-file-name': boolean;
}
interface DownloadParams {
    'file-id': string;
}

interface PublicUploadParams {
    'file-name': string;
    'phone-number': string;
}

interface PublicDownloadParams {
    'file-id': string;
    code: string;
}

interface PublicSendParams {
    'file-id': string;
}

interface PublicValidateParams {
    'file-id': string;
}

export const decryptFile = async (fileName: string, fileType: string, file: File, restoreFileName: boolean) => {
    const uploadParams: UploadParams = {
        'file-name': encodeURIComponent(fileName),
        'restore-file-name': restoreFileName
    };

    return getUploadSignedUrl(uploadParams).then((response) => {
        return uploadFileViaUrl(response.url, file)
            .then(() => response.fileId)
            .catch((err) => {
                throw err;
            });
    });
};

export const decryptPublicFile = async (fileName: string, file: File, phoneNumber: string) => {
    const uploadParams: PublicUploadParams = {
        'file-name': encodeURIComponent(fileName),
        'phone-number': phoneNumber
    };

    return getPublicUploadSignedUrl(uploadParams).then((response) => {
        return uploadFileViaUrl(response.url, file)
            .then(() => response.fileId)
            .catch((err) => {
                throw err;
            });
    });
};

const getUploadSignedUrl = async (params: UploadParams) => {
    const getInit = {
        headers: {
            Authorization: await getAuthorizationBearer()
        },
        response: true,
        queryStringParameters: {
            ...params
        }
    };
    return API.get('DocumentSafeAPI', '/file/actions/decrypt/upload', getInit).then((response) => response.data);
};

const getPublicUploadSignedUrl = async (params: PublicUploadParams) => {
    const getInit = {
        response: true,
        queryStringParameters: {
            ...params
        }
    };
    return API.get('DocumentSafeAPI', '/public/file/actions/decrypt/upload', getInit).then((response) => response.data);
};

const uploadFileViaUrl = async (signedUrl: string, file: File) => {
    return axios.put(signedUrl, file);
};

export const getDecryptUrl = async (fileId: string) => {
    const downloadParams: DownloadParams = {
        'file-id': fileId
    };

    const getUrlWithTimeout = async (downloadParams: DownloadParams, resolve: any, reject: any) => {
        setTimeout(() => {
            getDownloadSignedUrl(downloadParams)
                .then((response) => {
                    if (response.fileState === 'DONE') {
                        resolve(response);
                    } else if (response.fileState === 'ERROR') {
                        reject();
                    } else if (response.fileState === 'UNKNOWN_FILE') {
                        reject('UNKNOWN_FILE');
                    } else {
                        getUrlWithTimeout(downloadParams, resolve, reject);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        }, 1000);
    };

    return new Promise((resolve, reject) => {
        getUrlWithTimeout(downloadParams, resolve, reject);
    });
};

export const validatePublicFile = async (fileId: string) => {
    const params: PublicValidateParams = {
        'file-id': fileId
    };

    const getValidationWithTimeout = async (params: PublicValidateParams, resolve: any, reject: any) => {
        setTimeout(() => {
            getPublicDecryptValidationStatus(params)
                .then((response) => {
                    if (response.fileState === 'VALIDATION_SUCCESS') {
                        //waiting for code
                        resolve(response);
                    } else if (response.fileState === 'ERROR') {
                        //error
                        reject('ERROR');
                    } else if (response.fileState === 'UNKNOWN_FILE') {
                        //unknown file
                        reject('UNKNOWN_FILE');
                    } else if (response.fileState === 'VALIDATION_ERROR') {
                        //missmatch
                        reject('VALIDATION_ERROR');
                    } else if (response.fileState === 'DONE') {
                        //downalood url already sent
                        reject('DONE');
                    } else {
                        getValidationWithTimeout(params, resolve, reject);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        }, 1000);
    };

    return new Promise((resolve, reject) => {
        getValidationWithTimeout(params, resolve, reject);
    });
};

const getPublicDecryptValidationStatus = async (params: PublicValidateParams) => {
    const getInit = {
        response: true,
        queryStringParameters: {
            ...params
        }
    };
    const status = await API.get('DocumentSafeAPI', '/public/file/actions/decrypt/status', getInit)
        .then((response) => response.data)
        .catch((err) => console.error(err));
    if (!status) {
        throw new Error('Cannot get validation status.');
    }

    return status;
};

export const downloadPublicFile = async (fileId: string, code: string) => {
    const downloadParams: PublicDownloadParams = {
        'file-id': fileId,
        code: code
    };
    return getPublicDecryptDownloadSignedUrl(downloadParams);
};

export const sendPublicDecryptCode = async (fileId: string) => {
    const params: PublicSendParams = {
        'file-id': fileId
    };
    return resendPublicDecryptCode(params);
};

const resendPublicDecryptCode = async (params: PublicSendParams) => {
    const getInit = {
        response: true,
        queryStringParameters: {
            ...params
        }
    };
    return await API.get('DocumentSafeAPI', '/public/file/actions/decrypt/send-code', getInit).then(
        (response) => response.data
    );
};

const getPublicDecryptDownloadSignedUrl = async (params: PublicDownloadParams) => {
    const getInit = {
        response: true,
        queryStringParameters: {
            ...params
        }
    };

    const url = await API.get('DocumentSafeAPI', '/public/file/actions/decrypt/download', getInit)
        .then((response) => response.data.url)
        .catch((err) => {
            if (err?.response?.status === 400 && err.response.data.message === 'Invalid code') {
                throw new Error('INVALID_CODE');
            }
            throw new Error('ERROR');
        });
    if (!url) {
        throw new Error('Cannot get download signed url.');
    }
    return url;
};

const getDownloadSignedUrl = async (params: DownloadParams) => {
    const getInit = {
        headers: {
            Authorization: await getAuthorizationBearer()
        },
        response: true,
        queryStringParameters: {
            ...params
        }
    };
    const signedUrl = await API.get('DocumentSafeAPI', '/file/actions/decrypt/download', getInit)
        .then((response) => response.data)
        .catch((err) => console.error(err));
    if (!signedUrl) {
        throw new Error('Cannot get download signed url.');
    }
    return signedUrl;
};
