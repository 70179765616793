import styled from 'styled-components';

export const ContentColumnCenter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ContentSpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    @media (max-width: 767px) {
        flex-direction: column;
        align-items: start;
    }
`;

export const ContentTableActionButton = styled.div`
    display: flex;
    align-items: center;

    & > button {
        font-weight: 700;
        color: #0076db;
    }
`;
