import React from 'react';
import { Breadcrumb, Content, Grid } from 'rsuite';
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
    const { t } = useTranslation();

    const NotFoundPageBreadcrumb: React.FC<string> = (separator) => (
        <Breadcrumb separator={separator}>
            <Breadcrumb.Item href="/">{t('ACTIONS.GO_BACK_CONTACT_LIST')}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t('NOT_FOUND_PAGE.ERROR_404')}</Breadcrumb.Item>
        </Breadcrumb>
    );

    return (
        <Content>
            <Grid>
                {NotFoundPageBreadcrumb('<')}
                <h1>{t('NOT_FOUND_PAGE.ERROR_404')}</h1>
                <h3>{t('NOT_FOUND_PAGE.PAGE_NOT_FOUND')}</h3>
            </Grid>
        </Content>
    );
};

export default NotFoundPage;
