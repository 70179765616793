import styled from 'styled-components';

export const Container = styled.div`
    background-color: #ffca2833;
    display: flex;
    padding: 15px 20px;
    border-radius: 6px;
    margin-bottom: 30px;
    box-shadow: 4px 3px 16px rgba(33, 62, 153, 0.2);

    & > img {
        margin-right: 20px;
    }

    & > div > p {
        line-height: 22px;
    }
`;
