import styled from 'styled-components';

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: end;

    & > button:nth-child(2) {
        background: #a8071a;
        padding: 8px 12px;
    }
`;
