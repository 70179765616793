import React from 'react';
import { Loader } from 'rsuite';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { useTranslation } from 'react-i18next';

const LoaderWrapper = () => {
    const { t } = useTranslation();
    const isLoader = useSelector((state: RootState) => state.loader.showLoader);
    return <>{isLoader && <Loader backdrop content={t('common.loading')} vertical style={{ zIndex: 10000 }} />}</>;
};

export default LoaderWrapper;
