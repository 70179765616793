import styled from 'styled-components';

export const DataContactWrapper = styled.div`
    margin-bottom: 30px;

    & > div {
        width: 50%;

        @media (max-width: 1024px) {
            width: 100%;
        }
    }
`;

export const BreadcrumbWrapper = styled.div`
    margin-bottom: 20px;

    & > a {
        display: inline-flex;
        align-items: center;
        font-weight: 600;

        & > svg {
            margin-right: 5px;
        }
    }
`;
