import styled from 'styled-components';

export const ButtonWrapper = styled.div`
    & > button {
        font-size: 16px;
        display: flex;
        align-items: center;
        background: #0076db;
        color: #fff;
        padding: 2px 20px 2px 2px;

        &:hover,
        &:focus {
            background-color: #213e99 !important;
            color: #fff !important;
        }
    }
`;

export const GoogleIconWrapper = styled.div`
    width: 35px;
    height: 35px;
    background: #fff;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px 0px 0px 4px;
    margin-right: 20px;

    & > img {
        height: 25px;
    }
`;
