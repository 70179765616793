import { Auth } from 'aws-amplify';
import { Form, Schema } from 'rsuite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TypeAttributes } from 'rsuite/esm/@types/common';
import { showErrorNotification } from '../../services/notificationService';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import ButtonGoogle from '../../components/ButtonGoogle';
import Button from '../../components/Button';
import LoginPanelWrapper from '../../components/LoginPanelWrapper';
import { ForgotPasswordLinkWrapper } from '../../styles/LoginPageStyles';
import { Link } from '../../styles/UtilsStyles';

const LoginPage: React.FC = () => {
    const formRef = React.useRef<any>();
    const [checkTrigger, setCheckTrigger] = React.useState<TypeAttributes.CheckTrigger>('change');
    const [formValue, setFormValue] = React.useState<any>(null);
    const [formError, setFormError] = React.useState<any>({});

    const isUserCognitoLogin = useSelector((state: RootState) => state.featureToggle.userCognitoLogin);

    const { t } = useTranslation();

    const model = Schema.Model({
        name: Schema.Types.StringType().isRequired(t('COMMON.IS_REQUIRED') as string),
        password: Schema.Types.StringType().isRequired(t('COMMON.IS_REQUIRED') as string)
    });

    const handleSubmit = async () => {
        if (!formRef.current.check()) {
            return;
        }
        return Auth.signIn(formValue.name, formValue.password).catch((error) => {
            if (error) {
                if (error.toString().includes('UserNotFoundException')) {
                    showErrorNotification(t('LOGIN_PAGE.USER_NOT_FOUND_EXCEPTION') as string);
                } else if (error.toString().includes('UserNotConfirmedException')) {
                    showErrorNotification(t('LOGIN_PAGE.USER_NOT_CONFIRMED') as string);
                } else if (error.toString().includes('NotAuthorizedException')) {
                    showErrorNotification(t('LOGIN_PAGE.NOT_AUTHORIZED_EXCEPTION') as string);
                } else if (error.toString().includes('PasswordResetRequiredException')) {
                    showErrorNotification(t('LOGIN_PAGE.PASSWORD_RESET_REQUIRED_EXCEPTION') as string);
                }
            } else {
                showErrorNotification(t('LOGIN_PAGE.LOGIN_FAILED') as string);
            }
        });
    };

    return (
        <LoginPanelWrapper
            children={
                <>
                    <h3>{t('LOGIN_PAGE.LOG_IN')}</h3>
                    {!isUserCognitoLogin && <ButtonGoogle />}
                    {isUserCognitoLogin && (
                        <>
                            <Form
                                ref={formRef}
                                onChange={setFormValue}
                                onCheck={setFormError}
                                formError={formError}
                                model={model}
                                checkTrigger={checkTrigger}
                            >
                                <Form.Group controlId="name">
                                    <Form.ControlLabel>{t('LOGIN_PAGE.USER_NAME')}</Form.ControlLabel>
                                    <Form.Control name="name" />
                                </Form.Group>
                                <Form.Group controlId="password">
                                    <Form.ControlLabel>{t('LOGIN_PAGE.PASSWORD')}</Form.ControlLabel>
                                    <Form.Control name="password" type="password" autoComplete="off" />
                                    <ForgotPasswordLinkWrapper>
                                        <Link href="/forgot-password">{t('LOGIN_PAGE.FORGOT_PASSWORD')}</Link>
                                    </ForgotPasswordLinkWrapper>
                                </Form.Group>
                                <Form.Group>
                                    <Button
                                        appearance={'primary'}
                                        onClick={handleSubmit}
                                        text={t('LOGIN_PAGE.SIGN_IN')}
                                        type="submit"
                                    />
                                </Form.Group>
                            </Form>
                            <Link href="/register">{t('LOGIN_PAGE.REGISTER')}</Link>
                        </>
                    )}
                </>
            }
        />
    );
};

export default LoginPage;
