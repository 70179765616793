import React, { useEffect, useState } from 'react';
import { Checkbox, Form, Schema, TagPicker, Uploader } from 'rsuite';
import Modal from './Modal';
import {
    GridButtons,
    InformationMessage,
    MarginBottom,
    SuccessMessage,
    TagPickerWrapper,
    UploaderWrapper
} from '../styles/ModalStyles';
import Button from './Button';
import { showErrorNotification, showInfoNotification } from '../services/notificationService';
import { getUrl, MAX_FILE_SIZE, uploadFile } from '../services/uploadServices';
import { momentDate } from '../utils/dateUtils';
import { TypeAttributes } from 'rsuite/esm/@types/common';
import { useTranslation } from 'react-i18next';
import { downloadFile } from '../services/downloadFile';
import { getContactsSelect } from '../services/contactService';

interface ModalFileEncryptProps {
    openModalFileEncrypt: boolean;
    setOpenModalFileEncrypt: (openModalFileDecryption: boolean) => void;
}

interface IUploadFileState {
    url: any;
    fileName: string;
    showMessage: boolean;
}

interface IFormValue {
    newFileName?: string;
}

interface Contact {
    label: string;
    value: string;
}

const ModalFileEncrypt: React.FC<ModalFileEncryptProps> = (props) => {
    const [value, setValue] = useState<any>([]);
    const [clearFile, setClearFile] = useState<boolean>(false);
    const [uploadFileState, setUploadFileState] = useState<IUploadFileState>({
        url: '',
        fileName: '',
        showMessage: false
    });
    const [renameFileName, setRenameFileName] = useState<boolean>(false);
    const [selectedContacts, setSelectedContacts] = useState<Array<string>>([]);
    const [contacts, setContacts] = useState<Contact[]>([]);
    const [loadingContacts, setLoadingContacts] = useState<boolean>(true);

    const encryptionMethod = 'aes256';
    const { t } = useTranslation();

    const { StringType } = Schema.Types;
    const model = Schema.Model({
        newFileName: StringType()
            .isRequired(t('COMMON.IS_REQUIRED') as string)
            .pattern(/^[^\\/:\*\?"<>\|]+$/, t('MODAL_UPLOAD_FILE.PATTERN') as string)
    });

    const [checkTrigger, setCheckTrigger] = React.useState<TypeAttributes.CheckTrigger>('change');
    const [formValue, setFormValue] = React.useState<IFormValue>({
        newFileName: t('MODAL_UPLOAD_FILE.DOCUMENT_') + momentDate()
    });
    const [formError, setFormError] = React.useState<IFormValue>({});

    useEffect(() => {
        if (props.openModalFileEncrypt) {
            loadContacts();
        }
    }, [props]);

    const loadContacts = () => {
        setLoadingContacts(true);
        getContactsSelect()
            .then((data) => {
                setContacts(
                    data.map((contact) => ({
                        label: contact.email,
                        value: contact.id
                    }))
                );
            })
            .catch(() => {
                showErrorNotification(t('NOTIFICATION.ERROR_NOTIFICATION') as string);
            })
            .finally(() => {
                setLoadingContacts(false);
            });
    };

    /* https://github.com/rsuite/rsuite/issues/1147 */
    const clearUploader = () => {
        setValue([]);
        setClearFile(true);
        setClearFile(false);
        setRenameFileName(false);
        setFormValue({
            newFileName: t('MODAL_UPLOAD_FILE.DOCUMENT_') + momentDate()
        });
        setSelectedContacts([]);
    };

    const handleEncryptNextFile = () => {
        setUploadFileState({ url: '', fileName: '', showMessage: false });
        clearUploader();
    };

    const handleUpload = () => {
        return uploadFile(
            value[0].blobFile.name,
            value[0].blobFile.type,
            value[0].blobFile,
            selectedContacts,
            false,
            encryptionMethod,
            renameFileName ? formValue.newFileName : null
        )
            .then((fileId) => {
                setUploadFileState({ ...uploadFileState, fileName: value[0].blobFile.name });
                return getUrl(fileId)
                    .then((responseUrl: string) => {
                        setUploadFileState((uploadFileState) => ({
                            ...uploadFileState,
                            url: responseUrl,
                            showMessage: true
                        }));
                        downloadFile(responseUrl);
                        clearUploader();
                    })
                    .catch((err) => {
                        showErrorNotification(t('NOTIFICATION.ERROR_NOTIFICATION') as string);
                    });
            })
            .catch((err) => {
                showErrorNotification(t('NOTIFICATION.ERROR_NOTIFICATION') as string);
            });
    };

    const handleExitModalUploadFile = () => {
        clearUploader();
        setUploadFileState({ url: '', fileName: '', showMessage: false });
        setContacts([]);
    };

    const handleCloseModalFileEncrypt = () => {
        props.setOpenModalFileEncrypt(false);
    };

    const handleUploadFileChange = (fileList: any) => {
        setUploadFileState({ ...uploadFileState, showMessage: false });
        if (fileList.length > 0) {
            const lastFile = fileList[fileList.length - 1];
            if (lastFile.blobFile.size > MAX_FILE_SIZE) {
                showErrorNotification(t('NOTIFICATION.THE_SELECTED_FILE_IS_TOO_LARGE') as string);
                setValue([]);
            } else {
                setValue([fileList[fileList.length - 1]]);
            }
        } else {
            setValue(fileList);
        }
    };

    const isUploaderValid = () => {
        if (selectedContacts.length === 0) {
            return false;
        }

        if (renameFileName) {
            return value.length === 1 && Object.keys(formError).length === 0;
        }
        return value.length === 1;
    };

    const handleCopyLink = () => {
        const fileDecryptionUrl = `${window.location.origin}/file-decryption`;

        navigator.clipboard
            .writeText(fileDecryptionUrl)
            .then(() => {
                showInfoNotification(t('MODAL_FILE_ENCRYPT.TEXT_COPIED_NOTIFICATION') as string);
            })
            .catch((err) => {
                showErrorNotification(t('MODAL_FILE_ENCRYPT.COPY_FAILED_NOTIFICATION') as string);
            });
    };

    return (
        <Modal
            open={props.openModalFileEncrypt}
            onClose={handleCloseModalFileEncrypt}
            onExited={handleExitModalUploadFile}
            title={t('MODAL_UPLOAD_FILE.HEADER')}
            body={
                <>
                    {!uploadFileState.showMessage && (
                        <>
                            {!clearFile && (
                                <Uploader
                                    draggable
                                    fileList={value}
                                    autoUpload={false}
                                    onChange={handleUploadFileChange}
                                    multiple={false}
                                    /* Action jest wymagany, dlatego został ustawiony pusty string */
                                    action={''}
                                >
                                    <UploaderWrapper>
                                        <p>
                                            <a>{t('MODAL_UPLOAD_FILE.CHOOSE_FILE')}</a>
                                            {t('MODAL_UPLOAD_FILE.OR_DRAG_HERE')}
                                        </p>
                                    </UploaderWrapper>
                                </Uploader>
                            )}
                            <p style={{ textAlign: 'right', marginBottom: 10 }}>
                                {t('MODAL_UPLOAD_FILE.MAXIMUM_SIZE_MESSAGE')}
                            </p>
                            <h5>{t('MODAL_UPLOAD_FILE.FILE_NAME')}</h5>
                            <Checkbox
                                checked={renameFileName}
                                onChange={(value, checked) => {
                                    setRenameFileName(checked);
                                }}
                            >
                                {t('MODAL_UPLOAD_FILE.CHECKBOX_FILE_NAME')}
                            </Checkbox>
                            {renameFileName && (
                                <Form
                                    onChange={setFormValue}
                                    onCheck={setFormError}
                                    formError={formError}
                                    formDefaultValue={formValue}
                                    model={model}
                                    checkTrigger={checkTrigger}
                                >
                                    <Form.Group className="newFileName">
                                        <Form.ControlLabel>{t('MODAL_UPLOAD_FILE.NEW_FILE_NAME')}</Form.ControlLabel>
                                        <Form.Control name="newFileName" errorMessage={formError.newFileName} />
                                    </Form.Group>
                                </Form>
                            )}
                            <h5>{t('ACTIONS.SELECT_RECIPIENTS')}</h5>
                            <p style={{ marginBottom: 20 }}>{t('ACTIONS.SELECT_RECIPIENTS_INSTRUCTION')}</p>
                            <TagPickerWrapper>
                                <TagPicker
                                    data={contacts}
                                    searchable={true}
                                    onChange={(value) => setSelectedContacts(value || [])}
                                    value={selectedContacts}
                                    loading={loadingContacts}
                                />
                            </TagPickerWrapper>
                            <InformationMessage>
                                <img
                                    src="/assets/img/iconInfoNotification.svg"
                                    alt={t('ALT.INFORMATION_ICON') as string}
                                />
                                <p>
                                    {t('MODAL_FILE_ENCRYPT.DECRYPT_FILE_NOTIFICATION1')}
                                    <a onClick={handleCopyLink}>
                                        {t('MODAL_FILE_ENCRYPT.DECRYPT_FILE_NOTIFICATION_LINK')}
                                    </a>
                                    {t('MODAL_FILE_ENCRYPT.DECRYPT_FILE_NOTIFICATION2')}
                                </p>
                            </InformationMessage>
                        </>
                    )}
                    {!uploadFileState.showMessage && (
                        <GridButtons>
                            <Button
                                appearance="primary"
                                canClick={isUploaderValid}
                                onClick={handleUpload}
                                text={t('MODAL_UPLOAD_FILE.ENCRYPT_FILE')}
                                disabled={!isUploaderValid()}
                            />
                        </GridButtons>
                    )}
                    {uploadFileState.showMessage && (
                        <>
                            <SuccessMessage>
                                <img src="/assets/img/iconSuccess.svg" alt={t('ALT.SUCCESS_ICON') as string} />
                                <div>
                                    <h5>{t('MODAL_UPLOAD_FILE.SUCCESS_MESSAGE_HEADER')}</h5>
                                    <p>
                                        {t('MODAL_UPLOAD_FILE.SUCCESS_MESSAGE_TEXT')}
                                        <a href={uploadFileState.url}> {t('MODAL_UPLOAD_FILE.SUCCESS_MESSAGE_LINK')}</a>
                                        .
                                    </p>
                                </div>
                            </SuccessMessage>
                            <MarginBottom>
                                <h5>{t('MODAL_FILE_ENCRYPT.CALL_TO_REMEMBER')}</h5>
                                <p>
                                    {t('MODAL_FILE_ENCRYPT.DECRYPT_FILE_NOTIFICATION1')}
                                    <a onClick={handleCopyLink}>
                                        {t('MODAL_FILE_ENCRYPT.DECRYPT_FILE_NOTIFICATION_LINK')}
                                    </a>
                                    {t('MODAL_FILE_ENCRYPT.DECRYPT_FILE_NOTIFICATION3')}
                                </p>
                            </MarginBottom>
                            <GridButtons>
                                <Button
                                    appearance="primary"
                                    onClick={handleEncryptNextFile}
                                    text={t('ACTIONS.ENCRYPT_NEXT_FILE')}
                                />
                            </GridButtons>
                        </>
                    )}
                    <GridButtons>
                        <Button appearance={'link'} onClick={handleCloseModalFileEncrypt} text={t('COMMON.CANCEL')} />
                    </GridButtons>
                </>
            }
        />
    );
};

export default ModalFileEncrypt;
