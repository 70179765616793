import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';

export const getJwtToken = async () => {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
};

export const getAuthorizationBearer = async () => {
    return `Bearer ${await getJwtToken()}`;
};

export const hasUserScope = (user: CognitoUser | any, scope: string) => {
    return user.signInUserSession.idToken.payload['scp'].split(' ').includes(scope);
};
