export const validatePassword = (value: string, t: any) => {
    const lengthCheck = value.length >= 8;
    const upperCaseCheck = /[A-Z]/.test(value);
    const lowerCaseCheck = /[a-z]/.test(value);
    const numberCheck = /[0-9]/.test(value);
    const specialCharCheck = /[!@#$%^&*]/.test(value);

    return !!value
        ? [
              !lengthCheck || !lowerCaseCheck || !upperCaseCheck || !numberCheck || !specialCharCheck
                  ? t('PASSWORD_VALIDATION.PASSWORD_REQUIREMENTS')
                  : '',
              !lengthCheck ? t('PASSWORD_VALIDATION.MIN_LENGTH') : '',
              !lowerCaseCheck ? t('PASSWORD_VALIDATION.CONTAINS_LOWERCASE') : '',
              !upperCaseCheck ? t('PASSWORD_VALIDATION.CONTAINS_UPPERCASE') : '',
              !numberCheck ? t('PASSWORD_VALIDATION.CONTAINS_NUMBER') : '',
              !specialCharCheck ? t('PASSWORD_VALIDATION.CONTAINS_SPECIAL_CHARACTER') : ''
          ]
              .filter(Boolean)
              .join('\n- ')
        : (t('COMMON.IS_REQUIRED') as string);
};
