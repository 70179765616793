import React, { useEffect, useState } from 'react';
import { getContact, getContactAudits } from '../../services/contactService';
import { showErrorNotification } from '../../services/notificationService';
import { Breadcrumb, Content, Grid, Table } from 'rsuite';
import { DisplayFlex } from '../../styles/ModalStyles';
import ContactDetails from '../../components/ContactDetails';
import { useParams } from 'react-router';
import Search from '../../components/Search';
import { formatDate } from '../../utils/dateUtils';
import { BreadcrumbWrapper, DataContactWrapper } from '../../styles/HistoryPageStyles';
import ArowBackIcon from '@rsuite/icons/ArowBack';
import AuditType from '../../model/AuditType';
import { useDispatch } from 'react-redux';
import { loaderActions } from '../../store/loader';
import Pagination from '../../components/Pagination';
import { ITableState, TableSearchParameters } from '../../model/TableHelpers';
import ContactHistory from '../../model/ContactHistory';
import { useTranslation } from 'react-i18next';

interface IContactState {
    contact: any;
}

const HistoryPage = () => {
    const [contactState, setContactState] = useState<IContactState>({
        contact: null
    });
    const [filterOn, setFilterOn] = useState<boolean>(false);
    const [filterString, setFilterString] = useState<string>('');
    const [tableHistoryState, setTableHistoryState] = useState<ITableState<ContactHistory>>({
        loading: false,
        limit: 10,
        page: 1,
        data: []
    });
    const { t } = useTranslation();
    const { userId } = useParams();

    const dispatch = useDispatch();
    const showLoader = () => {
        dispatch(loaderActions.showLoader());
    };
    const hideLoader = () => {
        dispatch(loaderActions.hideLoader());
    };

    const HistoryBreadcrumb = () => (
        <BreadcrumbWrapper>
            <Breadcrumb.Item href="/">
                <ArowBackIcon />
                {t('ACTIONS.GO_BACK_CONTACT_LIST')}
            </Breadcrumb.Item>
        </BreadcrumbWrapper>
    );

    useEffect(() => {
        loadTableHistoryData({ limit: tableHistoryState.limit, resetPagePosition: true });
        loadContactData();
    }, []);

    const loadContactData = () => {
        if (userId === undefined) {
            showErrorNotification(t('NOTIFICATION.ERROR_NOTIFICATION') as string);
            return;
        }
        showLoader();
        getContact(userId)
            .then((contact) => {
                hideLoader();
                setContactState({ ...contactState, contact: contact.item });
            })
            .catch((err) => {
                showErrorNotification(t('NOTIFICATION.ERROR_NOTIFICATION') as string);
            });
    };

    const loadTableHistoryData = (tableSearchParameters: TableSearchParameters) => {
        if (userId === undefined) {
            showErrorNotification(t('NOTIFICATION.ERROR_NOTIFICATION') as string);
            return;
        }
        showLoader();
        setTableHistoryState((tableHistoryState) => ({ ...tableHistoryState, loading: true }));
        getContactAudits(
            userId,
            tableSearchParameters.limit,
            tableSearchParameters.filter,
            tableSearchParameters.lastKey
        )
            .then((data) => {
                hideLoader();
                const contactAudits: any[] = [];
                if (data && data.items && data.items.length > 0) {
                    data.items.forEach((item: any) => {
                        contactAudits.push({
                            ...item,
                            createDate: formatDate(item.createDate),
                            operation: AuditType.from(item)
                        });
                    });
                }
                setTableHistoryState((tableHistoryState) => ({
                    ...tableHistoryState,
                    loading: false,
                    data: contactAudits,
                    lastKey: data.lastKey,
                    limit: tableSearchParameters.limit === null ? tableHistoryState.limit : tableSearchParameters.limit
                }));
            })
            .catch((err) => {
                showErrorNotification(t('NOTIFICATION.ERROR_NOTIFICATION') as string);
            });
    };

    const handleChangeLimit = (limit: number) => {
        setTableHistoryState((tableHistoryState) => ({ ...tableHistoryState, limit: limit, page: 1 }));
        loadTableHistoryData({ limit: limit, resetPagePosition: true } as TableSearchParameters);
    };

    const handleChangePage = (page: number) => {
        if (tableHistoryState.page === page) {
            return;
        }
        setTableHistoryState((tableState) => ({ ...tableState, page: page }));
        if (page !== 1) {
            loadTableHistoryData({
                limit: tableHistoryState.limit,
                lastKey: tableHistoryState.lastKey,
                resetPagePosition: false
            });
        } else {
            loadTableHistoryData({ limit: tableHistoryState.limit, resetPagePosition: false });
        }
    };

    const handleFiltering = () => {
        if (filterString.trim().length >= 3) {
            loadTableHistoryData({ limit: null, filter: filterString, resetPagePosition: true });
            setFilterOn(true);
        } else if (filterString.trim().length === 0) {
            loadTableHistoryData({ limit: tableHistoryState.limit, resetPagePosition: true });
            setFilterOn(false);
        }
    };

    return (
        <Content>
            <Grid>
                <HistoryBreadcrumb />
                <h3>{t('HISTORY_PAGE.HISTORY')}</h3>
                <DataContactWrapper>
                    <h5>{t('CONTACT.CONTACT_DETAILS')}</h5>
                    <DisplayFlex>
                        <ContactDetails header={t('CONTACT.EMAIL')} details={contactState.contact?.email} />
                        <ContactDetails header={t('CONTACT.PHONE')} details={contactState.contact?.phoneNumber} />
                    </DisplayFlex>
                    {contactState.contact?.description && (
                        <ContactDetails header={t('CONTACT.DESCRIPTION')} details={contactState.contact?.description} />
                    )}
                </DataContactWrapper>
                <h5>{t('HISTORY_PAGE.SEARCH')}</h5>
                <Search
                    filterString={filterString}
                    onChangeSearch={(e) => setFilterString(e)}
                    handleFiltering={handleFiltering}
                    text={t('SEARCH.SEARCH_AFTER')}
                />
                <Table autoHeight={true} loading={tableHistoryState.loading} data={tableHistoryState.data} wordWrap>
                    <Table.Column width={300}>
                        <Table.HeaderCell>Data</Table.HeaderCell>
                        <Table.Cell dataKey="createDate"></Table.Cell>
                    </Table.Column>

                    <Table.Column width={300}>
                        <Table.HeaderCell>{t('COMMON.USER')}</Table.HeaderCell>
                        <Table.Cell dataKey="creator" />
                    </Table.Column>

                    <Table.Column width={300}>
                        <Table.HeaderCell>{t('COMMON.ACTION')}</Table.HeaderCell>
                        <Table.Cell dataKey="operation.name" />
                    </Table.Column>

                    <Table.Column width={300}>
                        <Table.HeaderCell>{t('COMMON.ADDITIONAL_INFORMATION')}</Table.HeaderCell>
                        <Table.Cell dataKey="operation.message" />
                    </Table.Column>
                </Table>
                {!filterOn && (
                    <Pagination
                        tableState={tableHistoryState}
                        setTableState={setTableHistoryState}
                        handleChangePage={handleChangePage}
                        handleChangeLimit={handleChangeLimit}
                    />
                )}
                <HistoryBreadcrumb />
            </Grid>
        </Content>
    );
};

export default HistoryPage;
