import React, { useState } from 'react';
import { DisplayFlex, GridButtons } from '../styles/ModalStyles';
import Modal from './Modal';
import Button from './Button';
import { generatePassword, sendPassword } from '../services/passwordService';
import { showErrorNotification, showSuccessNotification } from '../services/notificationService';
import { formatDate } from '../utils/dateUtils';
import ContactDetails from './ContactDetails';
import { IContactState } from './ContactsTable';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { ISmsMessageModal } from '../pages/dashboard/Dashboard';

interface ModalPasswordProps {
    contactState: IContactState;
    setContactState: (contactState: any) => void;
    smsMessageModal: ISmsMessageModal;
    setSmsMessageModal: (smsMessageModal: any) => void;
}

const ModalPassword: React.FC<ModalPasswordProps> = (props) => {
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const { t } = useTranslation();

    const isSmsMessages = useSelector((state: RootState) => state.featureToggle.smsMessages);

    const handleCloseModalPassword = () => {
        props.setContactState({ ...props.contactState, showModal: false });
    };

    const handleExitModalPassword = () => {
        props.setContactState({ contactId: null, showModal: false });
    };

    const handleGeneratePasswordSubmit = () => {
        return generatePassword(props.contactState.contact.id)
            .then((response) => {
                if (!isSmsMessages) {
                    props.setSmsMessageModal({
                        openModal: true,
                        smsMessage: t('MODAL_SMS_MESSAGE.GENERATE_PASSWORD_MESSAGE'),
                        password: response.data.password
                    });
                }
                showSuccessNotification(t('NOTIFICATION.GENERATED_AND_SENT_PASSWORD') as string);
                props.setContactState({
                    ...props.contactState,
                    contact: {
                        ...props.contactState.contact,
                        passwordLastSendDate: formatDate(response.data.sentDate),
                        passwordLastGenerateDate: formatDate(response.data.sentDate)
                    }
                });
            })
            .catch((err) => {
                showErrorNotification(t('NOTIFICATION.PASSWORD_GENERATION_FAILED') as string);
            });
    };

    const handleSendPasswordSubmit = () => {
        return sendPassword(props.contactState.contact.id, false)
            .then((response) => {
                if (!isSmsMessages) {
                    props.setSmsMessageModal({
                        openModal: true,
                        smsMessage: t('MODAL_SMS_MESSAGE.SEND_PASSWORD_MESSAGE'),
                        password: response.data.password
                    });
                }
                showSuccessNotification(t('NOTIFICATION.CURRENT_PASSWORD_SENT') as string);
                props.setContactState({
                    ...props.contactState,
                    contact: { ...props.contactState.contact, passwordLastSendDate: formatDate(response.data.sentDate) }
                });
            })
            .catch((err) => {
                showErrorNotification(t('NOTIFICATION.COULD_NOT_SEND_PASSWORD') as string);
            });
    };

    return (
        <Modal
            open={props.contactState.showModal}
            onClose={handleCloseModalPassword}
            onExited={handleExitModalPassword}
            title={t('MODAL_PASSWORD.PASSWORD')}
            body={
                <>
                    <h5>{t('CONTACT.CONTACT_DETAILS')}</h5>
                    <DisplayFlex>
                        <ContactDetails header={t('CONTACT.EMAIL')} details={props.contactState.contact?.email} />
                        <ContactDetails header={t('CONTACT.PHONE')} details={props.contactState.contact?.phoneNumber} />
                    </DisplayFlex>
                    {props.contactState.contact?.description && (
                        <ContactDetails
                            header={t('CONTACT.DESCRIPTION')}
                            details={props.contactState.contact?.description}
                        />
                    )}
                    <h5>Hasło</h5>
                    <DisplayFlex>
                        <ContactDetails
                            header={t('MODAL_PASSWORD.PASSWORD_GENERATION_DATE')}
                            details={props.contactState.contact?.passwordLastGenerateDate}
                        />
                        <ContactDetails
                            header={t('MODAL_PASSWORD.PASSWORD_SUBMISSION_DATE')}
                            details={props.contactState.contact?.passwordLastSendDate}
                        />
                    </DisplayFlex>
                </>
            }
            footer={
                <GridButtons>
                    <Button
                        onClick={handleSendPasswordSubmit}
                        text={t('MODAL_PASSWORD.SEND_CURRENT_PASSWORD')}
                        appearance="primary"
                    />
                    <Button
                        appearance={'ghost'}
                        onClick={handleGeneratePasswordSubmit}
                        text={t('MODAL_PASSWORD.GENERATE_AND_SEND_NEW_PASSWORD')}
                    />
                    <Button appearance={'link'} onClick={handleCloseModalPassword} text={t('COMMON.CANCEL')} />
                </GridButtons>
            }
        />
    );
};

export default ModalPassword;
