import React from 'react';
import Button from './Button';
import { GridButtons, IconStep, TutorialInfoText, TutorialStepWrapper } from '../styles/ModalStyles';
import { useTranslation } from 'react-i18next';
import Modal from './Modal';

interface openModalTutorialProps {
    openModalTutorial: boolean;
    handleExitModalTutorial: () => void;
}

const ModalTutorial: React.FC<openModalTutorialProps> = (props) => {
    const { t } = useTranslation();

    return (
        <Modal
            open={props.openModalTutorial}
            onClose={props.handleExitModalTutorial}
            title={t('MODAL_TUTORIAL.HEADER')}
            body={
                <>
                    <TutorialStepWrapper>
                        <IconStep>1</IconStep>
                        <p>{t('MODAL_TUTORIAL.TEXT_STEP_1')}</p>
                    </TutorialStepWrapper>
                    <TutorialStepWrapper>
                        <IconStep>2</IconStep>
                        <p>{t('MODAL_TUTORIAL.TEXT_STEP_2')}</p>
                    </TutorialStepWrapper>
                    <TutorialStepWrapper>
                        <IconStep>3</IconStep>
                        <p>{t('MODAL_TUTORIAL.TEXT_STEP_3')}</p>
                    </TutorialStepWrapper>
                    <TutorialInfoText>
                        {t('MODAL_TUTORIAL.DOCUMENTATION_TEXT1')}
                        <a href="https://kryptli.com/dokumentacja" target="_blank">
                            {t('MODAL_TUTORIAL.DOCUMENTATION_LINK')}
                        </a>
                        {t('MODAL_TUTORIAL.DOCUMENTATION_TEXT2')}
                    </TutorialInfoText>
                    <TutorialInfoText>{t('MODAL_TUTORIAL.TUTORIAL_INFO_TEXT')}</TutorialInfoText>
                </>
            }
            footer={
                <GridButtons>
                    <Button
                        onClick={props.handleExitModalTutorial}
                        text={t('MODAL_TUTORIAL.I_UNDERSTAND')}
                        appearance="primary"
                    />
                    <Button appearance={'link'} onClick={props.handleExitModalTutorial} text={t('COMMON.CANCEL')} />
                </GridButtons>
            }
        />
    );
};

export default ModalTutorial;
