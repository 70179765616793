import { API } from 'aws-amplify';
import { getAuthorizationBearer } from './authService';

export const generatePassword = async (contactId: string) => {
    const postInit = {
        headers: {
            Authorization: await getAuthorizationBearer()
        },
        body: {
            clientId: contactId
        }
    };

    return API.post('DocumentSafeAPI', '/password/generate-and-send', postInit);
};

export const sendPassword = async (contactId: string, newContact: boolean) => {
    const postInit = {
        headers: {
            Authorization: await getAuthorizationBearer()
        },
        body: {
            clientId: contactId,
            newContact: newContact
        }
    };

    return API.post('DocumentSafeAPI', '/password/send', postInit);
};
