import React from 'react';
import { useTranslation } from 'react-i18next';
import { TypeAttributes } from 'rsuite/esm/@types/common';
import { Form, Schema } from 'rsuite';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { showErrorNotification, showSuccessNotification } from '../../services/notificationService';
import Button from '../../components/Button';
import { BoxShadowWrapper, Container, ResetPasswordErrorMessageWrapper } from '../../styles/ForgotPasswordStyles';
import ErrorMessage from '../../components/ErrorMessage';
import { Link } from '../../styles/UtilsStyles';
import { validatePassword } from '../../utils/passwordUtils';

const ResetPasswordPage = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const userName = searchParams.get('user_name');
    const confirmationCode = searchParams.get('confirmation_code');
    const formRef = React.useRef<any>();
    const [formValue, setFormValue] = React.useState<any>(null);
    const [formError, setFormError] = React.useState<any>({});
    const [checkTrigger, setCheckTrigger] = React.useState<TypeAttributes.CheckTrigger>('change');
    const navigate = useNavigate();
    const [showResetPassword, setShowResetPassword] = React.useState<boolean>(true);
    const [passwordErrorMessages, setPasswordErrorMessages] = React.useState<string>('');
    const [errorMessage, setErrorMessage] = React.useState<string>('');

    const model = Schema.Model({
        password: Schema.Types.StringType()
            .pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, t('COMMON.IS_PASSWORD') as string)
            .isRequired(t('COMMON.IS_REQUIRED') as string),
        repeatPassword: Schema.Types.StringType()
            .addRule((value, data) => {
                return value === formValue.password;
            }, t('REGISTER.PASSWORDS_ARE_NOT_IDENTICAL') as string)
            .isRequired(t('COMMON.IS_REQUIRED') as string)
    });

    const handlePasswordChange = (value: string) => {
        const errorMessages = validatePassword(value, t);
        setPasswordErrorMessages(errorMessages);
    };

    const handleResetPassword = async () => {
        if (typeof userName === 'string') {
            if (confirmationCode != null) {
                try {
                    await Auth.forgotPasswordSubmit(userName, confirmationCode, formValue.password);
                    navigate(`/login`);
                    showSuccessNotification(t('FORGOT_PASSWORD_PAGE.PASSWORD_CHANGED') as string);
                } catch (err) {
                    setShowResetPassword(false);
                    if (err) {
                        if (err.toString().includes('ExpiredCodeException')) {
                            setErrorMessage(t('RESET_PASSWORD_PAGE.EXPIRED_CODE_EXCEPTION') as string);
                        }
                    } else {
                        setErrorMessage(t('FORGOT_PASSWORD_PAGE.PASSWORD_RESET_FAILED') as string);
                    }
                }
            }
        }
    };

    const isPasswordValid = () => {
        return formValue && formValue.password && !formError.password && !formError.repeatPassword;
    };

    const handleRefresh = () => {
        navigate(`/forgot-password`);
    };

    return (
        <Container>
            <img src="/assets/img/logo.svg" alt={t('ALT.KRYPTLI_APP_LOGO') as string} />
            <BoxShadowWrapper>
                {showResetPassword && (
                    <>
                        <h3>{t('FORGOT_PASSWORD_PAGE.SET_NEW_PASSWORD')}</h3>
                        <Form
                            ref={formRef}
                            onChange={setFormValue}
                            onCheck={setFormError}
                            formError={formError}
                            model={model}
                            checkTrigger={checkTrigger}
                        >
                            <Form.Group controlId="password">
                                <Form.ControlLabel>{t('FORGOT_PASSWORD_PAGE.ENTER_NEW_PASSWORD')}</Form.ControlLabel>
                                <Form.Control
                                    name="password"
                                    type="password"
                                    autoComplete="off"
                                    onChange={handlePasswordChange}
                                    errorMessage={passwordErrorMessages}
                                    className="passwordFormControl"
                                />
                            </Form.Group>
                            <Form.Group controlId="repeatPassword">
                                <Form.ControlLabel>{t('REGISTER.REPEAT_PASSWORD')}</Form.ControlLabel>
                                <Form.Control name="repeatPassword" type="password" autoComplete="off" />
                            </Form.Group>
                            <Form.Group>
                                <Button
                                    appearance="primary"
                                    onClick={handleResetPassword}
                                    text={t('FORGOT_PASSWORD_PAGE.SET_NEW_PASSWORD')}
                                    disabled={!isPasswordValid()}
                                    type="submit"
                                />
                            </Form.Group>
                        </Form>
                        <Link href="/login">{t('COMMON.GO_BACK_LOGIN_PAGE')}</Link>
                    </>
                )}
                {!showResetPassword && (
                    <ResetPasswordErrorMessageWrapper>
                        <ErrorMessage
                            body={
                                <>
                                    <h5>{t('RESET_PASSWORD_PAGE.ERROR_WHILE_RESETTING_PASSWORD')}</h5>
                                    <p>{errorMessage}</p>
                                </>
                            }
                        />
                        <Button
                            appearance="primary"
                            onClick={handleRefresh}
                            text={t('RESET_PASSWORD_PAGE.TRY_AGAIN')}
                        />
                        <Link href="/login">{t('COMMON.GO_BACK_LOGIN_PAGE')}</Link>
                    </ResetPasswordErrorMessageWrapper>
                )}
            </BoxShadowWrapper>
        </Container>
    );
};

export default ResetPasswordPage;
