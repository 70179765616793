import React from 'react';
import { Form, Radio, RadioGroup, Popover, Whisper } from 'rsuite';
import { CopyInfoWrapper, PopoverContent } from '../styles/EncryptionMethodsStyles';
import Button from './Button';
import { showInfoNotification } from '../services/notificationService';
import { useTranslation } from 'react-i18next';

interface encryptionMethodProps {
    encryptionMethodState: string;
    setEncryptionMethodState: (value: string) => void;
}

const EncryptionMethods: React.FC<encryptionMethodProps> = (encryptionMethodState) => {
    const { t } = useTranslation();

    const tooltipAES256 = (
        <Popover>
            <PopoverContent>
                {t('ENCRYPTION_METHODS.TOOLTIP_AES256')}
                <a href="https://7-zip.org.pl/" target="_blank">
                    7-zip
                </a>
                ,{' '}
                <a href="https://www.winrar.pl/winrar/pobierz" target="_blank">
                    WinRAR
                </a>
                ,{' '}
                <a href="https://www.winzip.com/en/" target="_blank">
                    WinZIP
                </a>{' '}
                {t('ENCRYPTION_METHODS.OR_OTHER')}
                <br />
                <CopyInfoWrapper>
                    {t('ENCRYPTION_METHODS.INFORMATION_FOR_CONTACT')}
                    <Button
                        appearance="link"
                        text={t('ENCRYPTION_METHODS.COPY_TEXT')}
                        onClick={() => {
                            showInfoNotification(t('ENCRYPTION_METHODS.TEXT_COPIED_NOTIFICATION') as string, 2500);
                            navigator.clipboard.writeText(t('ENCRYPTION_METHODS.CLIPBOARD_TEXT'));
                        }}
                    />
                </CopyInfoWrapper>
            </PopoverContent>
        </Popover>
    );
    const tooltipZip = (
        <Popover>
            <PopoverContent>{t('ENCRYPTION_METHODS.TOOLTIP_ZIP')}</PopoverContent>
        </Popover>
    );

    return (
        <Form.Group style={{ marginBottom: 15 }}>
            <RadioGroup
                name="encryptionMethods"
                defaultValue={encryptionMethodState.encryptionMethodState}
                onChange={(value) => {
                    encryptionMethodState.setEncryptionMethodState(value as string);
                }}
            >
                <Radio value="aes256">
                    Zaawansowana (AES-256){' '}
                    <Whisper
                        style={{ widows: 200 }}
                        placement="rightStart"
                        controlId="control-id-hover-enterable"
                        trigger="hover"
                        speaker={tooltipAES256}
                        enterable
                    >
                        <img src="/assets/img/iconInfo.svg" alt={t('ALT.MORE_INFORMATION_ICON') as string} />
                    </Whisper>
                </Radio>
                <Radio value="zip20">
                    Podstawowa (Zip 2.0){' '}
                    <Whisper
                        placement="rightStart"
                        controlId="control-id-hover"
                        trigger="hover"
                        speaker={tooltipZip}
                        enterable
                    >
                        <img src="/assets/img/iconInfo.svg" alt={t('ALT.MORE_INFORMATION_ICON') as string} />
                    </Whisper>
                </Radio>
            </RadioGroup>
        </Form.Group>
    );
};

export default EncryptionMethods;
