import { createSlice } from '@reduxjs/toolkit';

export interface UiBlockState {
    isBlock: boolean;
    background: boolean;
}

const initialUiBlockState: UiBlockState = {
    isBlock: false,
    background: false
};

const uiBlockSlice = createSlice({
    name: 'uiBlock',
    initialState: initialUiBlockState,
    reducers: {
        block(state) {
            state.isBlock = true;
        },
        blockWithBackground(state) {
            return (state = {
                isBlock: true,
                background: true
            });
        },
        unBlock(state) {
            return (state = {
                isBlock: false,
                background: false
            });
        }
    }
});

export const uiBlockActions = uiBlockSlice.actions;

export default uiBlockSlice.reducer;
