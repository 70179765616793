import React from 'react';
import { Button, Popover, Whisper, Dropdown } from 'rsuite';
import { useTranslation } from 'react-i18next';

const ContactActionLi = {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600
};

const ContactActionIcon = {
    marginRight: 10
};

interface ContactActionsProps {
    handleOnClickPassword: () => void | Promise<any>;
    handleOnClickHistory: () => void | Promise<any>;
    handleOnClickDeactivate: () => void | Promise<any>;
    handleOnClickEdit: () => void | Promise<any>;
    isContactDisabled: boolean;
}

const ContactActions: React.FC<ContactActionsProps> = (props) => {
    const { t } = useTranslation();
    const ref = React.useRef<any>();
    const closeActions = () => {
        if (ref.current) {
            ref.current.close();
        }
    };
    const onClickEdit = () => {
        closeActions();
        props.handleOnClickEdit();
    };
    const onClickPassword = () => {
        closeActions();
        props.handleOnClickPassword();
    };
    const onClickHistory = () => {
        closeActions();
        props.handleOnClickHistory();
    };
    const onClickDeactivate = () => {
        closeActions();
        props.handleOnClickDeactivate();
    };

    const actions = (
        <Popover arrow={false} full>
            <Dropdown.Menu>
                {!props.isContactDisabled && (
                    <>
                        <Dropdown.Item onSelect={onClickEdit} style={ContactActionLi}>
                            <img
                                src="/assets/img/iconEdit.svg"
                                alt={t('ALT.EDIT_ICON') as string}
                                style={ContactActionIcon}
                            />
                            {t('ACTIONS.EDIT_DATA')}
                        </Dropdown.Item>
                        <Dropdown.Item onSelect={onClickPassword} style={ContactActionLi}>
                            <img
                                src="/assets/img/iconPassword.svg"
                                alt={t('ALT.PASSWORD_ICON') as string}
                                style={ContactActionIcon}
                            />
                            {t('ACTIONS.PASSWORD_SETTINGS')}
                        </Dropdown.Item>
                    </>
                )}
                <Dropdown.Item onSelect={onClickHistory} style={ContactActionLi}>
                    <img
                        src="/assets/img/iconHistory.svg"
                        alt={t('ALT.HISTORY_ICON') as string}
                        style={ContactActionIcon}
                    />
                    {t('ACTIONS.ACTIVITY_HISTORY')}
                </Dropdown.Item>
                {!props.isContactDisabled && (
                    <Dropdown.Item onSelect={onClickDeactivate} style={ContactActionLi}>
                        <img
                            src="/assets/img/iconDeactivate.svg"
                            alt={t('ALT.DEACTIVATION_ICON') as string}
                            style={ContactActionIcon}
                        />
                        {t('ACTIONS.DEACTIVATE_CONTACT')}
                    </Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Popover>
    );

    return (
        <>
            <Whisper controlId={`control-id-1`} placement="bottomEnd" trigger="click" speaker={actions} ref={ref}>
                <Button appearance="link">
                    <img src="/assets/img/iconMore.svg" alt={t('ALT.MORE_ACTIONS_ICON') as string} />
                </Button>
            </Whisper>
        </>
    );
};

export default ContactActions;
