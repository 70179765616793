import React, { useContext, useEffect, useRef, useState } from 'react';
import { getEmailFromUser } from '../utils/userEmailUtils';
import { Auth } from 'aws-amplify';
import { GridMobileNavbar, GridMobileNavbarItems, GridNavbarItem, GridNavbarItems } from '../styles/NavbarItemsStyles';
import { UserContext } from '../context/UserContext';
import ModalTutorial from './ModalTutorial';
import Button from './Button';
import { Button as ButtonRsuite } from 'rsuite';
import { useMediaQuery } from 'react-responsive';
import CloseIcon from '@rsuite/icons/Close';
import ModalFileDecryption from './ModalFileDecryption';
import { uiBlockService } from '../services/uiBlockService';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
import ModalFileEncrypt from './ModalFileEncrypt';

interface NavbarProps {
    showNavbar: boolean;
    setShowNavbar: (showNavbar: boolean) => void;
}

const NavbarItems: React.FC<NavbarProps> = (props) => {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });
    const [openModalTutorial, setOpenModalTutorial] = useState<boolean>(false);
    const [openModalFileDecryption, setOpenModalFileDecryption] = useState<boolean>(false);
    const [openModalFileEncrypt, setOpenModalFileEncrypt] = useState<boolean>(false);
    const { t } = useTranslation();
    const user = useContext(UserContext);
    const tutorialKey = 'isTutorialShowed';
    const ref = useRef();

    useOnClickOutside(ref, () => handleCloseNavbar());

    const handleOpenTutorialModal = () => {
        setOpenModalTutorial(true);
        handleCloseNavbar();
    };

    const handleOpenModalFileDecryption = () => {
        setOpenModalFileDecryption(true);
        handleCloseNavbar();
    };

    const handleOpenModalFileEncrypt = () => {
        setOpenModalFileEncrypt(true);
        handleCloseNavbar();
    };

    useEffect(() => {
        if (!localStorage.getItem(tutorialKey)) {
            setOpenModalTutorial(true);
        }
    }, []);

    useEffect(() => {
        if (isDesktopOrLaptop && props.showNavbar) {
            handleCloseNavbar();
        }
    }, []);

    const handleExitModalTutorial = () => {
        setOpenModalTutorial(false);
        localStorage.setItem(tutorialKey, 'true');
    };

    const handleCloseNavbar = () => {
        props.setShowNavbar(false);
        uiBlockService.unBlock();
    };

    return (
        <>
            {isDesktopOrLaptop && (
                <GridNavbarItems>
                    <GridNavbarItem>
                        <Button
                            appearance="link"
                            onClick={handleOpenModalFileEncrypt}
                            text={t('ACTIONS.ENCRYPT_FILE')}
                        />
                    </GridNavbarItem>
                    <GridNavbarItem>
                        <Button
                            appearance="link"
                            onClick={handleOpenModalFileDecryption}
                            text={t('NAVBAR_ITEMS.DECRYPT_FILE')}
                        />
                    </GridNavbarItem>
                    <GridNavbarItem>
                        <Button appearance="link" onClick={handleOpenTutorialModal} text={t('NAVBAR_ITEMS.TUTORIAL')} />
                    </GridNavbarItem>
                    <span>|</span>
                    <GridNavbarItem>
                        <img src="/assets/img/iconUser.svg" alt={t('ALT.USER_ICON') as string} />
                        <p>{getEmailFromUser(user)}</p>
                    </GridNavbarItem>
                    <GridNavbarItem>
                        <Button appearance="link" onClick={() => Auth.signOut()} text={t('NAVBAR_ITEMS.LOG_OUT')} />
                        <img src="/assets/img/iconLogOut.svg" alt={t('ALT.APP_LOGOUT_ICON') as string} />
                    </GridNavbarItem>
                </GridNavbarItems>
            )}
            {isTabletOrMobile && (
                <GridMobileNavbar show={props.showNavbar} ref={ref}>
                    <ButtonRsuite appearance="link" onClick={handleCloseNavbar}>
                        <CloseIcon />
                    </ButtonRsuite>
                    <GridMobileNavbarItems>
                        <p>{getEmailFromUser(user)}</p>
                        <Button
                            appearance="link"
                            onClick={handleOpenModalFileEncrypt}
                            text={t('ACTIONS.ENCRYPT_FILE')}
                        />
                        <Button
                            appearance="link"
                            onClick={handleOpenModalFileDecryption}
                            text={t('NAVBAR_ITEMS.DECRYPT_FILE')}
                        />
                        <Button appearance="link" onClick={handleOpenTutorialModal} text={t('NAVBAR_ITEMS.TUTORIAL')} />
                        <Button appearance="link" onClick={() => Auth.signOut()} text={t('NAVBAR_ITEMS.LOG_OUT')} />
                    </GridMobileNavbarItems>
                </GridMobileNavbar>
            )}
            <ModalTutorial openModalTutorial={openModalTutorial} handleExitModalTutorial={handleExitModalTutorial} />
            <ModalFileDecryption
                openModalFileDecryption={openModalFileDecryption}
                setOpenModalFileDecryption={setOpenModalFileDecryption}
            />
            <ModalFileEncrypt
                openModalFileEncrypt={openModalFileEncrypt}
                setOpenModalFileEncrypt={setOpenModalFileEncrypt}
            />
        </>
    );
};

export default NavbarItems;
