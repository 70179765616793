import React from 'react';
import { Container } from '../styles/ErrorMessageStyles';
import { useTranslation } from 'react-i18next';

interface ErrorMessageProps {
    body?: React.ReactNode;
}
const ErrorMessage: React.FC<ErrorMessageProps> = (props) => {
    const { t } = useTranslation();
    return (
        <Container>
            <img src="/assets/img/iconInformation.svg" alt={t('ALT.INFORMATION_ICON') as string} />
            <div>{props.body}</div>
        </Container>
    );
};

export default ErrorMessage;
