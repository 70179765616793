import { PaginationWrapper } from '../styles/ContactsTableStyles';
import React from 'react';
import { Pagination as RsPagination } from 'rsuite';
import { ITableState } from '../model/TableHelpers';

interface PaginationProps<T> {
    tableState: ITableState<T>;
    setTableState: (tableState: any) => void;
    handleChangePage: (page: number) => void;
    handleChangeLimit: (limit: number) => void;
}

const Pagination: React.FC<PaginationProps<any>> = (props) => {
    return (
        <PaginationWrapper>
            <p>Ilość wpisów na stronie</p>
            <RsPagination
                total={props.tableState.page * props.tableState.limit + (!!props.tableState.lastKey ? 1 : 0)}
                first={true}
                prev={false}
                ellipsis={true}
                boundaryLinks={false}
                next={true}
                maxButtons={1}
                size="xs"
                layout={['limit', '|', 'pager']}
                limitOptions={[10, 25, 50]}
                limit={props.tableState.limit}
                activePage={props.tableState.page}
                onChangePage={props.handleChangePage}
                onChangeLimit={props.handleChangeLimit}
            />
        </PaginationWrapper>
    );
};

export default Pagination;
