import { createContext, useState, useEffect } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { Loader } from 'rsuite';
import { CognitoUser } from 'amazon-cognito-identity-js';

export const UserContext = createContext({} as CognitoUser | any);

type Props = {
    children?: React.ReactNode;
};

export const UserContextProvider: React.FC<Props> = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        checkUser();
    }, []);

    // (Only once) - when the component mounts, create a listener for any auth events.
    useEffect(() => {
        Hub.listen('auth', () => {
            // Hub listens for auth events that happen.
            // i.e. Sign in, sign out, sign up, etc.
            // Whenever an event gets detected, run the checkUser function
            checkUser();
        });
    }, []);

    const checkUser = async () => {
        try {
            // Get the current authenticated user
            const user = await Auth.currentAuthenticatedUser();
            if (user) {
                // set the user in state
                setUser(user);
            }
        } catch (error) {
            // Error occurs if there is no user signed in.
            // set the user to null if there is no user.
            setUser(null);
        }
        setLoading(false);
    };

    return loading ? <Loader center /> : <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>;
};
